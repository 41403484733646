import XAvatar from "../XAvatar/XAvatar";

type Props = {
  evaluatorId: string;
  evaluatorName: string;
  totalScore: number;
  onFocus: string | null;
  setOnFocus: (onFocus: string) => void;
};

const EvaluatorScore = ({
  evaluatorId,
  evaluatorName,
  totalScore,
  onFocus,
  setOnFocus,
}: Props) => {
  return (
    <div
      className={`flex cursor-pointer rounded-xl border border-[#E4E7EC] p-4 ${
        evaluatorId === onFocus ? "border-blue-deep" : null
      }`}
      onClick={() => setOnFocus(evaluatorId)}
    >
      <div className="flex w-1/2 flex-row items-center gap-x-4">
        <XAvatar
          id={evaluatorId}
          name={evaluatorName}
          size={35}
          fontSize={14}
        />
        <div className="flex flex-col">
          <p>Name</p>
          <p>{evaluatorName}</p>
        </div>
      </div>
      <div className="w-1/2">
        <div className="flex flex-col">
          <p>Total Score</p>
          <p>{totalScore} %</p>
        </div>
      </div>
    </div>
  );
};

export default EvaluatorScore;
