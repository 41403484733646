// Libraries
import Moment from "react-moment";

// Modules
import { Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";

// API
import { readNotification } from "../../api/notification";

// Contexts
import { useAuthContext } from "../../contexts/AuthContextProvider";
import { useStateContext } from "../../contexts/ContextProvider";

// Custom Hooks
import useLogout from "../../hooks/useLogout";

// Types
import { AuthContextType } from "../../@types/authContext";
import { ContextType } from "../../@types/context";
import { ErrorInterface } from "../../@types/response";
import { NotificationInterface } from "../../@types/notification";

type Props = {
  datas: NotificationInterface[];
};

const Notification = ({ datas }: Props) => {
  // Libraries
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  // Contexts
  const { handleClick, isClicked } = useStateContext() as ContextType;
  const { auth } = useAuthContext() as AuthContextType;

  // Hooks
  const logout = useLogout();

  // Events
  const handleShowAll = () => {
    handleClick("notification", !isClicked.notification);

    navigate("user/notifications");
  };

  const handleClickNotification = (id: string, link: string) => {
    readNotificationMutation.mutate({
      userId: auth?.id!,
      notificationId: id,
    });

    handleClick("notification", !isClicked.notification);

    navigate(link);
  };

  // Mutation
  const readNotificationMutation = useMutation(readNotification, {
    onSuccess(data) {
      queryClient.invalidateQueries(["notifications", auth?.id]);
    },
    onError(err: ErrorInterface) {
      if (err.response.status === 401) {
        const unauthorized = err.response.status;

        logout(unauthorized);
      }
    },
  });

  // JSX
  return (
    <div className="nav-item absolute right-1 top-20 w-80">
      <div className="grid grid-cols-1 divide-shadow-text w-full bg-white rounded shadow-lg">
        <div id="headerCard" className="px-5 py-3">
          <p className="text-comment">Notification</p>
        </div>
        <Divider />
        <div className="min-h-fit max-h-72 overflow-auto">
          {(datas === undefined || datas.length < 1) && (
            <p className="text-comment px-5 py-4 text-gray-500">
              No new notifications
            </p>
          )}
          {datas?.map((data) => (
            <div
              key={data.id}
              className="hover:bg-[#F5F5F5] cursor-pointer"
              onClick={() => handleClickNotification(data.id, data.link)}
            >
              <p className="text-comment px-5 py-4">
                {data.message}{" "}
                <span className="font-semibold">
                  <Moment fromNow>{data.createdAt}</Moment>
                </span>
              </p>
            </div>
          ))}
        </div>
        <div className="mt-auto px-5 py-4 w-fit">
          <p
            onClick={handleShowAll}
            className="text-blue-mid hover:text-blue-deep text-hint cursor-pointer"
          >
            Show all notifications
          </p>
        </div>
      </div>
    </div>
  );
};

export default Notification;
