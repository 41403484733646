export const formatToCssStateClass = (string: string) => {
  let array = string.split("");

  for (let i = 0; i < array.length; i++) {
    if (array[i] === "_") {
      array[i] = "-";
    }
  }

  return array.join("").toLowerCase();
};

export const formatState = (string: string) => {
  let array = string.split("");

  for (let i = 0; i < array.length; i++) {
    if (array[i] === "_") {
      array[i] = " ";
    }
  }

  let string2 = array.join("").toLowerCase();

  let array2 = string2.split(" ");

  for (var i = 0; i < array2.length; i++) {
    array2[i] = array2[i].charAt(0).toUpperCase() + array2[i].slice(1);
  }

  return array2.join(" ");
};
