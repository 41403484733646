// Libraries
import Moment from "react-moment";
import parse from "html-react-parser";

// Modules
import { useQuery } from "@tanstack/react-query";

// API
import { getAttachments } from "../../api/file";

// Contexts
import { useAuthContext } from "../../contexts/AuthContextProvider";

// Components
import { AttachmentInline, XAvatar } from "../../components";

// Types
import { AuthContextType } from "../../@types/authContext";
import { AttachmentInterface } from "../../@types/attachment";

type Props = {
  id: string;
  userId: string;
  userName: string;
  body: string;
  createdAt: string;
  hasFile?: boolean;
  isPrivate: boolean;
};

const Comment = ({
  id,
  userId,
  userName,
  body,
  createdAt,
  hasFile,
  isPrivate,
}: Props) => {
  // Contexts
  const { auth } = useAuthContext() as AuthContextType;

  //Events
  const commentBgColor = (userId: string) => {
    const result = userId === auth?.id ? "#FCF7F2" : "#E6F0F9";

    return result;
  };

  const attachments = useQuery(
    ["comment-attachments", id],
    () => getAttachments(id),
    { enabled: !!hasFile }
  );

  // JSX
  return (
    <div
      className={`flex flex-row p-6 justify-start rounded shadow-sm border ${
        isPrivate && "border-dashed"
      }`}
      style={{
        backgroundColor: `${commentBgColor(userId)}`,
        borderColor: `${
          commentBgColor(userId) === "#FCF7F2" ? "#DB1919" : "#2B6AB1"
        }`,
      }}
    >
      <XAvatar id={userId} name={userName} size={35} fontSize={14} />
      <div className="flex flex-col">
        <p className="text-black-text font-normal text-[14px] leading-[18px]">
          {userName}
        </p>
        <span className="text-shadow-text font-normal text-[12px] leading-[15px]">
          On <Moment format="MMMM Do YYYY, h:mm:ss A ">{createdAt}</Moment>
          {isPrivate && "as Private"}
        </span>
        <p className="mt-2 text">{parse(body)}</p>
        {hasFile && (
          <div className="flex flex-col gap-2 mt-4">
            {attachments.data?.data.map((attachment: AttachmentInterface) => (
              <AttachmentInline
                key={attachment.id}
                id={attachment.fileId}
                name={attachment.fileName}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Comment;
