// Third Party Type Imports
import { useQuery } from "@tanstack/react-query";
import { BiLinkExternal } from "react-icons/bi";
import { FaRegStar } from "react-icons/fa";
import { HiShare } from "react-icons/hi";
import { Link } from "react-router-dom";

// API
import { getChallengeBanner } from "../../api/challenge";

// Utils
import { formatToCssStateClass, formatState } from "../../utils/state";

// Types
type Props = {
  id: string;
  title: string;
  state: string;
  challengeId?: string;
};

const ChallengeHero = ({ id, title, state, challengeId }: Props) => {
  // Data fetching
  const banner = useQuery(["challenge-banner", id], () =>
    getChallengeBanner(id)
  );

  return (
    <div
      id="header"
      className="flex flex-row justify-between w-full items-end p-8 h-[304px] rounded bg-slate-500 bg-cover bg-center"
      style={{ backgroundImage: `url(${banner.data}` }}
    >
      <div className="flex flex-col ">
        <span className={`status ${formatToCssStateClass(state)}`}>
          {formatState(state)}
        </span>
        <h1 className="mt-[18px] text-white text-3xl mix-blend-difference">
          {title}
        </h1>
      </div>
      <div className="flex text-left text-black-text">
        {challengeId ? (
          <Link to={`/challenges/${challengeId}`} className="no-underline">
            <BiLinkExternal />
          </Link>
        ) : (
          <>
            <HiShare className="mr-5" />
            <FaRegStar />
          </>
        )}
      </div>
    </div>
  );
};

export default ChallengeHero;
