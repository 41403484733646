// Custom Third Party Libraries
import { CommentForm } from "../pages/Solutions/Solution/consts/schemas";
import { axiosPrivate } from "./axios";

export const getComments = async (solutionId: string, evaluatorId?: string) => {
  if (evaluatorId) {
    return await axiosPrivate.get(
      `/comments?solutionId=${solutionId}&evaluatorId=${evaluatorId}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      },
    );
  }

  return await axiosPrivate.get(`/comments?solutionId=${solutionId}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    },
  });
};

export const createNewComment = async (values: CommentForm) => {
  return await axiosPrivate.post("/comments", values, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    },
  });
};
