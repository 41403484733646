import { useEffect, useState } from "react";
import { BsGear } from "react-icons/bs";
import { FcInspection } from "react-icons/fc";
import { FiCheckCircle } from "react-icons/fi";
import { GrGroup } from "react-icons/gr";
import { HiOutlineUserGroup } from "react-icons/hi";
import { MdOutlineDashboard } from "react-icons/md";
import {
  TbFileSearch,
  TbFileStar,
  TbFileText,
  TbFileTime,
} from "react-icons/tb";

import { countryOptions } from "../@consts/country";
import { ROLES } from "../@consts/roles";
import { AuthContextType } from "../@types/authContext";
import { useAuthContext } from "../contexts/AuthContextProvider";
import { formatCountry } from "../utils/country";

type PavilionSubsType = { name: string; to: string };

const useSidebarLinks = () => {
  const [pavilionSubs, setPavilionSubs] = useState<PavilionSubsType[]>([]);
  // Contexts
  const { auth } = useAuthContext() as AuthContextType;

  useEffect(() => {
    if (auth?.role === ROLES.ADMIN) {
      countryOptions.forEach(({ label }) => {
        const next: PavilionSubsType = {
          name: label,
          to: `pavilions/${formatCountry(label)}`,
        };

        setPavilionSubs((prev) => [...prev, { ...next }]);
      });
    } else {
      setPavilionSubs([
        {
          name: auth?.country ?? "",
          to: `pavilions/${formatCountry(auth?.country)}`,
        },
      ]);
    }
  }, [auth?.role, auth?.country]);

  const links = [
    {
      title: "Main Menu",
      links: [
        {
          name: "Dashboard",
          to: "dashboard",
          icon: <MdOutlineDashboard />,
          role: [ROLES.SEEKER, ROLES.EVALUATOR, ROLES.ADMIN],
        },
        {
          name: "Open Challenges",
          to: "challenges",
          icon: <TbFileSearch />,
          role: [ROLES.SEEKER, ROLES.EVALUATOR, ROLES.ADMIN],
        },
        {
          name: "Pavilions",
          icon: <TbFileStar />,
          role: [ROLES.SEEKER, ROLES.EVALUATOR, ROLES.ADMIN],
          subs: pavilionSubs,
        },
        {
          name: "Pending Challenges",
          to: "admin/challenges",
          icon: <TbFileTime />,
          role: [ROLES.ADMIN],
        },
        {
          name: "Evaluator",
          to: "evaluator/challenges",
          icon: <FcInspection />,
          role: [ROLES.EVALUATOR],
        },
        {
          name: "My challenges",
          to: "user/challenges",
          icon: <TbFileText />,
          role: [ROLES.SEEKER, ROLES.EVALUATOR],
        },
        {
          name: "My Solutions",
          to: "user/solutions",
          icon: <FiCheckCircle />,
          role: [ROLES.SEEKER, ROLES.EVALUATOR],
        },
        {
          name: "Users",
          to: "admin/users",
          icon: <HiOutlineUserGroup />,
          role: [ROLES.ADMIN],
        },
      ],
    },

    {
      title: "My Account",
      links: [
        {
          name: "Edit Profiles",
          to: "user/profiles",
          icon: <BsGear />,
          role: [ROLES.SEEKER, ROLES.EVALUATOR, ROLES.ADMIN],
        },
      ],
    },
  ];

  return links;
};

export default useSidebarLinks;
