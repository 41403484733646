// System
import { useEffect } from "react";

// Modules
import { AiOutlineMenu } from "react-icons/ai";
import { Avatar, Badge } from "@mui/material";
import { BsBell } from "react-icons/bs";
import { useQuery } from "@tanstack/react-query";

// API
import { getUserNotifications } from "../../api/notification";
import { getUserDetails, getUserPicture } from "../../api/user";

// Contexts
import { useAuthContext } from "../../contexts/AuthContextProvider";
import { useStateContext } from "../../contexts/ContextProvider";

// Components
import { Notification, UserProfile } from "../../components";

// Utils
import { stringAvatar } from "../../utils/avatar";

// Types
import { AuthContextType } from "../../@types/authContext";
import { ContextType } from "../../@types/context";

type NavButtonProps = {
  title: string;
  customFunc: () => void;
  color: string;
  icon: JSX.Element;
  badgeContent?: number;
};

// Constants
const NavButton = ({
  title,
  customFunc,
  icon,
  color,
  badgeContent,
}: NavButtonProps) => (
  <button
    type="button"
    onClick={() => customFunc()}
    style={{ color }}
    className="relative text-xl py-2 bg-white cursor-pointer"
  >
    <Badge badgeContent={badgeContent} color="error" overlap="circular">
      {icon}
    </Badge>
  </button>
);

const Navbar = () => {
  // Contexts
  const { auth } = useAuthContext() as AuthContextType;
  const {
    activeMenu,
    setActiveMenu,
    screenSize,
    setScreenSize,
    handleClick,
    isClicked,
  } = useStateContext() as ContextType;

  // Data Fetching
  const notifications = useQuery(
    ["notifications", auth?.id, { isRead: false }],
    () => getUserNotifications({ userId: auth?.id!, isRead: false })
  );

  const user = useQuery(["user", auth?.id], () => getUserDetails(auth?.id!));

  const userPicture = useQuery(["user-picture", auth?.id], () =>
    getUserPicture(auth?.id!)
  );

  // Effects
  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (screenSize !== undefined) {
      if (screenSize <= 900) {
        setActiveMenu(false);
      } else {
        setActiveMenu(true);
      }
    }
  }, [screenSize]);

  // JSX
  return (
    <div className="flex justify-between h-[72px] p-2 mx-6 relative">
      <NavButton
        title="Menu"
        customFunc={() => setActiveMenu(!activeMenu)}
        color="#323A46"
        icon={<AiOutlineMenu className="text-[24px]" />}
      />
      <div className="flex gap-2">
        <div className="flex items-center gap-2 py-2 rounded-lg mr-4">
          <div className="flex flex-col w-fit text-right mr-2">
            <span className="font-normal text-black text-[13px] leading-[20px]">
              Hi {user.data?.data.firstName}
            </span>
            <span className="font-normal text-[#787272] text-[12px] leading-[15px]">
              Welcome back!
            </span>
          </div>
          <Avatar
            src={userPicture.data!}
            {...stringAvatar(
              `${user.data?.data.firstName} ${user.data?.data.lastName}`
            )}
            className="w-[34px] h-[34px] text-sm cursor-pointer"
            onClick={() => handleClick("userProfile", !isClicked.userProfile)}
          />
        </div>
        <NavButton
          title="Notification"
          customFunc={() =>
            handleClick("notification", !isClicked.notification)
          }
          color="#323A46"
          badgeContent={notifications.data?.data.length}
          icon={<BsBell className="text-[24px]" />}
        />

        {isClicked.notification && (
          <Notification datas={notifications.data?.data} />
        )}
        {isClicked.userProfile && (
          <UserProfile datas={user.data?.data!} imageUrl={userPicture.data} />
        )}
      </div>
    </div>
  );
};

export default Navbar;
