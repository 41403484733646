// System
import { ReactNode } from "react";

type Props = {
  title: string;
  children: ReactNode;
};

const CommonCard = ({ title, children }: Props) => {
  return (
    <div className="mt-5 grid grid-cols-1 divide-y divide-shadow-text w-full bg-white rounded shadow">
      <div className="px-7 py-4">
        <h3>{title}</h3>
      </div>
      <div className="p-7">{children}</div>
    </div>
  );
};

export default CommonCard;
