import {
  Alert,
  AlertColor,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Snackbar,
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Field, FieldArray, Formik, FormikHelpers, getIn } from "formik";
import { useState } from "react";

import { containedButton } from "../../@styles/containedButton";
import { outlinedButton } from "../../@styles/outlinedButton";
import { ChallengeCriteriaInterface } from "../../@types/challenge";
import { ErrorInterface } from "../../@types/response";
import {
  getEvaluatorCriteriaScores,
  submitEvaluatorCriteriaScores,
} from "../../api/solution";
import useLogout from "../../hooks/useLogout";
import {
  EvaluatorCriteriaScoreForm,
  EvaluatorCriteriaScoreSchema,
} from "./const/schemas";
import { useSnackbarContext } from "../../contexts/SnackbarContextProvider";
import { SnackbarContextType } from "../../@types/snackbarContext";
import useSnackbar from "../../hooks/useSnackbar";
import { ALERT } from "../../@consts/alert";

type Props = {
  open: boolean;
  onClose: () => void;
  solutionId: string;
  challengeCriterias: ChallengeCriteriaInterface[];
};

const ErrorMessage = ({ name }: { name: string }) => (
  <FormHelperText error>
    <Field
      name={name}
      render={({ form }: HTMLFormElement) => {
        const error = getIn(form.errors, name);

        const touch = getIn(form.touched, name);

        return touch && error ? error : null;
      }}
    />
  </FormHelperText>
);

const CriteriaModal = ({
  open,
  onClose,
  solutionId,
  challengeCriterias,
}: Props) => {
  const [message, setMessage] = useState("");
  const logout = useLogout();
  const snackbar = useSnackbar();
  const { openSnackbar, setOpenSnackbar, alertType, alertBody } =
    useSnackbarContext() as SnackbarContextType;

  const handleSubmitForm = (
    values: EvaluatorCriteriaScoreForm,
    actions: FormikHelpers<EvaluatorCriteriaScoreForm>,
  ) => {
    submitForm(values);
  };

  const submitForm = (values: EvaluatorCriteriaScoreForm) => {
    submitFormMutation.mutate(values);
  };

  const submitFormMutation = useMutation(submitEvaluatorCriteriaScores, {
    onSuccess(data) {
      handleAlert(ALERT.success, "Evaluation score has been submitted");

      onClose();
    },
    onError: (err: ErrorInterface) => {
      if (err.response.status === 401) {
        const unauthorized = err.response.status;

        logout(unauthorized);
      }

      setMessage("Error, your evaluation score cant be submitted");
    },
  });

  const score = useQuery(["score", solutionId], () =>
    getEvaluatorCriteriaScores(solutionId ?? ""),
  );

  const handleAlert = (alertType: string, alertBody: string) =>
    snackbar(alertType, alertBody);

  return (
    <>
      <Formik
        initialValues={
          score.data?.data && score.data.data.length > 0
            ? ({
                data: score.data.data,
                id: solutionId,
              } as EvaluatorCriteriaScoreForm)
            : ({
                data: challengeCriterias.map((x) => {
                  return {
                    challengeCriteriaId: x.id,
                    score: 0,
                  };
                }),
                id: solutionId,
              } as EvaluatorCriteriaScoreForm)
        }
        validationSchema={EvaluatorCriteriaScoreSchema}
        onSubmit={handleSubmitForm}
        validateOnMount={true}
        enableReinitialize={true}
      >
        {(props) => (
          <Dialog open={open} onClose={onClose} fullWidth>
            <form onSubmit={props.handleSubmit} autoComplete="off">
              <DialogTitle id="alert-dialog-title">
                <h2>Challenge Evaluvation Criteria</h2>
              </DialogTitle>
              <DialogContent dividers>
                {message && <Alert severity="success">{message}</Alert>}
                <br />
                <Field type="hidden" id={"id"} name={"id"} value={solutionId} />
                <FieldArray name="data">
                  {(arrayHelpers) => (
                    <div className="grid grid-cols-4 items-center gap-x-10 gap-y-5">
                      <div className="col-span-3">Criteria</div>
                      <div>Score</div>
                      {props.values.data &&
                        props.values.data.length > 0 &&
                        props.values.data.map((data, index) => (
                          <>
                            <div className="col-span-3">
                              <div className="flex flex-col">
                                <p className="text-sm">
                                  {challengeCriterias.find(
                                    ({ id, label, percentage }) =>
                                      id === data.challengeCriteriaId,
                                  )?.label +
                                    " (" +
                                    challengeCriterias.find(
                                      ({ id, label, percentage }) =>
                                        id === data.challengeCriteriaId,
                                    )?.percentage +
                                    "%)"}
                                </p>
                                <ErrorMessage name={`data.${index}.score`} />
                              </div>
                            </div>
                            <Field
                              id={`data.${index}.score`}
                              name={`data.${index}.score`}
                              type="number"
                              inputProps={{ min: 0, max: 5 }}
                              onChange={(
                                e: React.ChangeEvent<
                                  HTMLInputElement | HTMLTextAreaElement
                                >,
                              ) => {
                                props.setFieldValue(
                                  `data.${index}.score`,
                                  e.target.value,
                                );
                              }}
                            />
                          </>
                        ))}
                    </div>
                  )}
                </FieldArray>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={onClose}
                  variant="outlined"
                  sx={outlinedButton}
                >
                  Cancel
                </Button>
                <Button type="submit" variant="contained" sx={containedButton}>
                  Submit
                </Button>
              </DialogActions>
            </form>
          </Dialog>
        )}
      </Formik>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={(e) => {
          setOpenSnackbar(false);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Alert severity={alertType as AlertColor}>{alertBody}</Alert>
      </Snackbar>
    </>
  );
};

export default CriteriaModal;
