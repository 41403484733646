// Libraries
import { AxiosResponse } from "axios";

// Custom Third Party Libraries
import { axiosPrivate } from "./axios";

// Schemas
import { ChallengeForm } from "../pages/Challenges/AddChallenge/consts/schemas";

// Constants
const baseURL = process.env.REACT_APP_BACKEND_SERVICE_URI;

export interface ChallengesFilter {
  country?: string;
  industryGroup?: string;
  disciplines?: string;
}

export const getOpenChallenges = async ({
  country = "",
  industryGroup = "",
  disciplines = "",
}: ChallengesFilter = {}) => {
  return await axiosPrivate.get(
    `/challenges?industryGroup=${industryGroup}&disciplines=${disciplines}&country=${country}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    }
  );
};

export const getChallengeDetails = async (challengeId: string) => {
  return await axiosPrivate.get(`/challenges/${challengeId}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    },
  });
};

export const createNewChallenge = async (values: ChallengeForm) => {
  return await axiosPrivate.post("/challenges", values, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    },
  });
};

export const editChallenge = async (values: ChallengeForm) => {
  return await axiosPrivate.put(`/challenges/${values.id}`, values, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    },
  });
};

export const getChallengeBanner = async (challengeId: string) => {
  const result = await fetch(`${baseURL}challenges/${challengeId}/banner`);

  if (!result.ok) {
    throw new Error("Network response was not ok");
  }

  const blob = await result.blob();

  const url = URL.createObjectURL(blob);

  return url;
};

export const getUserChallenges = async (userId: string) => {
  return await axiosPrivate.get(`/challenges?userId=${userId}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    },
  });
};

export const getPendingChallenges = async () => {
  return await axiosPrivate.get(`/challenges/pending-approval`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    },
  });
};

export const approveChallenge = async (challengeId: string) => {
  const accessToken = localStorage.getItem("accessToken") || "null";

  const result = await fetch(`${baseURL}challenges/${challengeId}/approve`, {
    method: "POST",
    credentials: "include",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (!result.ok) {
    throw new Error("Network response was not ok");
  }

  const json = await result.json();

  return json;
};

export const getChallengeEvaluators = async (
  challengeId: string
): Promise<AxiosResponse<{ userId?: string; userEmail: string }[]>> => {
  return await axiosPrivate.get(`/challenges/${challengeId}/evaluators`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    },
  });
};

export const assignEvaluators = async (values: {
  id: string;
  emails: string[];
}) => {
  return await axiosPrivate.post(
    `/challenges/${values.id}/evaluators`,
    values,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    }
  );
};

export const resendEvaluatorInvitation = async (values: {
  id: string;
  email: string;
}) => {
  return await axiosPrivate.get(
    `/challenges/${values.id}/evaluators/${values.email}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    }
  );
};

export const getEvaluatorChallenges = async () => {
  return await axiosPrivate.get(`/evaluators/challenges`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    },
  });
};
