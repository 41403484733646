type Props = {
  label: string;
  percentage: number;
  score: number;
};

const EvaluatorScoreList = ({ label, percentage, score }: Props) => {
  return (
    <div
      className={`flex cursor-pointer rounded-xl border border-[#E4E7EC] p-4`}
    >
      <div className="flex w-1/2 flex-row items-center gap-x-4">
        <div className="flex flex-col">
          <p>Criteria</p>
          <p>
            {label} ({percentage} %)
          </p>
        </div>
      </div>
      <div className="w-1/2">
        <div className="flex flex-col">
          <p>Score</p>
          <p>{score}</p>
        </div>
      </div>
    </div>
  );
};

export default EvaluatorScoreList;
