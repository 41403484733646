// Custom Third Party Libraries
import { axiosPrivate } from "./axios";

// Schemas
import { ReviewForm } from "../pages/Solutions/Solution/consts/schemas";
import { AxiosResponse } from "axios";

export const createNewReview = async (values: ReviewForm) => {
  return await axiosPrivate.post("/users/reviews", values, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    },
  });
};

export const getReviewDetails = async (
  reviewedObjectId: string
): Promise<AxiosResponse<{ isReviewed: boolean }>> => {
  return await axiosPrivate.get(
    `/users/reviews?reviewedObjectId=${reviewedObjectId}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    }
  );
};
