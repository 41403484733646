type Props = {
  h1: string;
  p?: string;
};

const ContentHeader = ({
  h1,
  p = "ManuVate Innovation Framework in Action.",
}: Props) => {
  return (
    <>
      <h1>{h1}</h1>
      <p className="heading-card-title text-shadow-text">{p}</p>
    </>
  );
};

export default ContentHeader;
