export const outlinedButton = {
  paddingY: "8px",
  paddingX: "24px",
  borderRadius: "4px",
  textTransform: "none",
  fontWeight: 500,
  fontSize: "15px",
  lineHeight: "20px",
  bgcolor: "#FFFFFF",
  borderColor: "#FFFFFF",
  color: "#A1A1A1",
  "&:hover": {
    bgcolor: "#292A61",
    borderColor: "#FFFFFF",
    color: "#FFFFFF",
  },
};
