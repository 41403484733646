// System
import { useEffect, useState } from "react";

// Modules
import { FaRegStar } from "react-icons/fa";
import { HiShare } from "react-icons/hi";
import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { IoBulbOutline } from "react-icons/io5";
import { AiOutlineClockCircle } from "react-icons/ai";

// API
import { getChallengeBanner } from "../../api/challenge";

// Components
import { XAvatar } from "../../components";

// Utils
import { daysRemaining } from "../../utils/moment";
import { formatChallengeTitle } from "../../utils/challenge";
import { formatState, formatToCssStateClass } from "../../utils/state";
import { formatToCurrency } from "../../utils/currency";

// Types
type Props = {
  id: string;
  imageId: string;
  title: string;
  state: string;
  awardAmount?: number;
  userId: string;
  userName?: string;
  endDate: string;
  solutionCount?: number;
};

const ChallengeCard = ({
  id,
  imageId,
  title,
  state,
  awardAmount,
  userId,
  userName,
  endDate,
  solutionCount,
}: Props) => {
  // Data State
  const [stateStyle, setStateStyle] = useState("pending-approval");
  const [daysColor, setDaysColor] = useState("text-red-alert");

  // Events
  const handleStateStyle = (state: string) => {
    setStateStyle(state);
  };

  const handleDaysColor = (days: number) => {
    if (days >= 60) {
      setDaysColor("text-green-alt");
    } else if (days < 60 && days >= 4) {
      setDaysColor("text-blue-mid");
    }
  };

  // Data fetching
  const banner = useQuery(["challenge-banner", id], () =>
    getChallengeBanner(id),
  );

  // Effects
  useEffect(() => {
    handleDaysColor(daysRemaining(endDate));
  }, [endDate]);

  useEffect(() => {
    handleStateStyle(formatToCssStateClass(state));
  }, [state]);

  return (
    <div className="flex flex-col rounded bg-white shadow">
      <Link to={`/challenges/${id}`} className="no-underline">
        <div
          className="h-36 rounded bg-white bg-cover bg-center p-5"
          style={{ backgroundImage: `url(${banner.data})` }}
        >
          <span className={`status ${stateStyle}`}>{formatState(state)}</span>
        </div>
      </Link>
      <div className="flex flex-col px-5 py-3">
        {awardAmount && (
          <p className="text-hint text-green-alt">
            Award: {formatToCurrency(awardAmount)}
          </p>
        )}
        <Link
          to={`/challenges/${id}`}
          className="heading-card-title mt-3 min-h-[58px] no-underline line-clamp-3"
        >
          {title}
        </Link>
        {userName && (
          <div className="mt-auto flex flex-col">
            <p className="micro-copy mt-3 mb-1 text-[12px]">Posted by</p>
            <div className="text-hint my-1 flex flex-row items-center">
              <XAvatar id={userId} name={userName} size={22} fontSize={10} />
              {userName}
            </div>
          </div>
        )}
      </div>
      <div className="text-hint mt-auto flex flex-row items-center justify-between rounded bg-slate-footer px-5 py-3">
        <div className={`flex flex-row ${daysColor}`}>
          <AiOutlineClockCircle className="mr-1" /> {daysRemaining(endDate)}{" "}
          days left
        </div>
        <div className="flex flex-row text-black-text">
          {solutionCount !== undefined ? (
            <>
              <IoBulbOutline className="mr-1" />
              <p>{solutionCount} solutions</p>
            </>
          ) : (
            <>
              <HiShare className="mr-2" />
              <FaRegStar />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChallengeCard;
