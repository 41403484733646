import * as yup from "yup";

export const SignInInitialValue = {
  userName: "",
  password: "",
};

export interface SignInForm {
  userName: string;
  password: string;
}

export const SignInSchema = yup.object().shape({
  userName: yup.string().required("Required"),
  password: yup.string().required("Required"),
});
