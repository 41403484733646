// Contexts
import { useSnackbarContext } from "../contexts/SnackbarContextProvider";

// Types
import { SnackbarContextType } from "../@types/snackbarContext";

const useSnackbar = () => {
  // Contexts
  const { setOpenSnackbar, setAlertType, setAlertBody } =
    useSnackbarContext() as SnackbarContextType;

  const snackbar = (alertType: string, alertBody: string) => {
    setOpenSnackbar(true);
    setAlertType(alertType);
    setAlertBody(alertBody);
  };

  return snackbar;
};

export default useSnackbar;
