// Third Party Type Imports
import { Avatar } from "@mui/material";
import { useQuery } from "@tanstack/react-query";

// API
import { getUserThumbnail } from "../../api/user";

// Utils
import { initialAvatar, stringToColor } from "../../utils/avatar";

type Props = {
  id: string;
  name: string;
  size: number;
  fontSize: number;
};

const XAvatar = ({ id, name, size, fontSize }: Props) => {
  const thumbnail = useQuery(["user-thumbnail", id], () =>
    getUserThumbnail(id)
  );

  const bgcolor = stringToColor(name);

  return (
    <Avatar
      src={thumbnail.data}
      children={initialAvatar(name)}
      className="mr-2"
      sx={{ width: size, height: size, fontSize, bgcolor }}
    />
  );
};

export default XAvatar;
