// Third Parties
import axios from "axios";

export default axios.create({
  baseURL: process.env.REACT_APP_BACKEND_SERVICE_URI,
});

export const axiosPrivate = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_SERVICE_URI,
  withCredentials: true,
});
