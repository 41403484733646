// Modules
import { EvaluatorCriteriaScoreForm } from "../components/CriteriaModal/const/schemas";
import { SolutionForm } from "../pages/Solutions/AddSolution/consts/schemas";
import { axiosPrivate } from "./axios";

export const getChallengeSolutions = async (challengeId: string) => {
  return await axiosPrivate.get(`/solutions?challengeId=${challengeId}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    },
  });
};

export const createNewSolution = async (values: SolutionForm) => {
  return await axiosPrivate.post("/solutions", values, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    },
  });
};

export const editSolution = async (values: SolutionForm) => {
  return await axiosPrivate.put(`/solutions/${values.id}`, values, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    },
  });
};

export const getUserSolutions = async (userId: string) => {
  return await axiosPrivate.get(`/solutions?userId=${userId}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    },
  });
};

export const getSolutionDetails = async (id: string) => {
  return await axiosPrivate.get(`/solutions/${id}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    },
  });
};

export const submitEvaluatorCriteriaScores = async ({
  id,
  data,
}: EvaluatorCriteriaScoreForm) => {
  return await axiosPrivate.post(`/solutions/${id}/criteria-scores`, data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    },
  });
};

export const getEvaluatorCriteriaScores = async (id: string) => {
  return await axiosPrivate.get(`/solutions/${id}/criteria-scores`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    },
  });
};
