import * as yup from "yup";

export const PrivateChatSchema = yup.object().shape({
  comment: yup.string().required("Required"),
  evaluatorId: yup.string().required("Required"),
  isPrivate: yup.boolean().required("Required"),
});

export interface PrivateChatForm
  extends yup.InferType<typeof PrivateChatSchema> {}

export const PrivateChatInitialForm = {
  comment: "",
  isPrivate: false,
} as PrivateChatForm;
