import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Formik, FormikHelpers } from "formik";
import { useCallback, useEffect, useRef, useState } from "react";
import { FileRejection } from "react-dropzone";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { BiTrash } from "react-icons/bi";

import { ALERT } from "../../@consts/alert";
import { containedButton } from "../../@styles/containedButton";
import { outlinedButton } from "../../@styles/outlinedButton";
import { CommentInterface } from "../../@types/comment";
import { FileInterface } from "../../@types/file";
import { ErrorInterface } from "../../@types/response";
import { createNewComment, getComments } from "../../api/comment";
import { getEvaluatorData } from "../../api/evaluator";
import { createNewAttachment, createNewFile } from "../../api/file";
import useLogout from "../../hooks/useLogout";
import useSnackbar from "../../hooks/useSnackbar";
import { getFileSize } from "../../utils/file";
import Comment from "../Comment/Comment";
import CommentEditor from "../CommentEditor/CommentEditor";
import Dropzone from "../Dropzone/Dropzone";
import {
  PrivateChatForm,
  PrivateChatInitialForm,
  PrivateChatSchema,
} from "./consts/schemas";

type Props = {
  open: boolean;
  onClose: () => void;
  solutionId: string;
};

const PrivateChatModal = ({ open, onClose, solutionId }: Props) => {
  const snackbar = useSnackbar();
  const logout = useLogout();

  const [commentField, setCommentField] = useState("");
  const [commentAttachments, setCommentAttachments] = useState<FileInterface[]>(
    [],
  );
  const privateCommentsEndRef = useRef<null | HTMLDivElement>(null);

  const scrollToBottom = () => {
    privateCommentsEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const removeSelectedAttachment = (id: string) => {
    setCommentAttachments((current) =>
      current.filter((file) => {
        return file.id !== id;
      }),
    );
  };

  const onDropFile = useCallback((acceptedFiles: File[]) => {
    acceptedFiles.forEach((file) => {
      const fd = new FormData();
      fd.append("file", file, file.name);

      uploadAttachmentMutation.mutate(fd);
    });
  }, []);

  const onDropRejected = useCallback((fileRejections: FileRejection[]) => {
    fileRejections.forEach((file) => {
      const errors: string[] = file.errors.map(({ message }) => message);

      const errorMessage: string = `${file.file.name} rejected. ${errors.join(
        ", ",
      )}`;

      snackbar(ALERT.error, errorMessage);
    });
  }, []);

  const handleAlert = (alertType: string, alertBody: string) =>
    snackbar(alertType, alertBody);

  const uploadAttachmentMutation = useMutation(createNewFile, {
    onSuccess(data, variables) {
      const file = variables.get("file") as File;

      const attachment = {
        name: file.name,
        fileType: file.type.split("/")[1],
        size: getFileSize(file.size),
        id: data.data.dataValues.id,
      };

      setCommentAttachments((prev) => [...prev, { ...attachment }]);
    },
    onError: (err: ErrorInterface) => {
      if (err.response.status === 401) {
        const unauthorized = err.response.status;

        logout(unauthorized);
      }

      const alertType = ALERT.error;
      const alertBody = err.response.data?.message || err.message;

      handleAlert(alertType, alertBody);
    },
  });

  const handleSubmitForm = (
    values: PrivateChatForm,
    actions: FormikHelpers<PrivateChatForm>,
  ) => {
    if (values.comment === "<p><br></p>") return;

    const hasFile = commentAttachments.length > 0 ? true : false;

    submitCommentMutation.mutate({
      ...values,
      solutionId,
      hasFile,
    });

    actions.setSubmitting(false);
  };

  const evaluator = useQuery(["evaluator", solutionId], () =>
    getEvaluatorData(solutionId ?? ""),
  );

  const comments = useQuery(
    [
      "solution-comments",
      {
        solutionId,
        evaluatorId:
          evaluator.data && evaluator.data.data && evaluator.data.data.id,
      },
    ],
    () =>
      getComments(
        solutionId,
        evaluator.data && evaluator.data.data && evaluator.data.data.id,
      ),
    { cacheTime: 0 },
  );

  const submitCommentMutation = useMutation(createNewComment, {
    onSuccess(data) {
      setCommentField("");

      comments.refetch();

      handleSubmitAttachments(data.data.id);
    },
    onError: (err: ErrorInterface) => {
      if (err.response.status === 401) {
        const unauthorized = err.response.status;

        logout(unauthorized);
      }

      const alertType = ALERT.error;
      const alertBody = err.response.data?.message || err.message;

      handleAlert(alertType, alertBody);
    },
  });

  const handleSubmitAttachments = (objectId: string) => {
    commentAttachments.map((attachment) => {
      submitAttachmentMutation.mutate({
        objectId,
        objectType: "COMMENT",
        fileId: attachment.id,
      });
    });
  };

  const submitAttachmentMutation = useMutation(createNewAttachment, {
    onSuccess(data, variables, context) {
      setCommentAttachments([]);
    },
    onError(error, variables, context) {},
  });

  useEffect(() => {
    scrollToBottom();
  }, [comments]);

  return (
    <Formik
      initialValues={
        evaluator.data?.data
          ? ({
              ...PrivateChatInitialForm,
              evaluatorId: evaluator.data.data.id,
            } as PrivateChatForm)
          : PrivateChatInitialForm
      }
      validationSchema={PrivateChatSchema}
      onSubmit={handleSubmitForm}
      validateOnMount={true}
      enableReinitialize={true}
    >
      {(props) => (
        <Dialog open={open} onClose={onClose} fullWidth>
          <form onSubmit={props.handleSubmit} autoComplete="off">
            <DialogTitle id="alert-dialog-title">
              <h2>Interact Challange Owner</h2>
            </DialogTitle>
            <DialogContent dividers>
              <div
                id="headerBody"
                className="flex h-80 flex-col gap-4 overflow-auto p-7 pt-6 "
              >
                {!comments.data?.data.length ? (
                  <div className="flex h-full w-full items-center justify-center text-shadow-text">
                    No comments yet
                  </div>
                ) : (
                  <>
                    <div className="flex flex-col gap-5">
                      {comments.data?.data.map((comment: CommentInterface) => (
                        <Comment
                          id={comment.id}
                          userId={comment.userId}
                          userName={comment.userName}
                          body={comment.comment}
                          createdAt={comment.createdAt}
                          hasFile={comment.hasFile}
                          key={comment.id}
                          isPrivate={comment.isPrivate}
                        />
                      ))}
                    </div>
                  </>
                )}
                <div ref={privateCommentsEndRef} />
              </div>

              <div className="rounded border border-shadow-text">
                <CommentEditor
                  setContents={commentField}
                  onChange={(context) => {
                    setCommentField(context);
                    props.setFieldValue("comment", context);
                  }}
                />
              </div>

              <div className="mt-6 flex h-fit flex-col rounded-[3px] border border-slate-300 p-3">
                <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                  {commentAttachments.map((file) => (
                    <div
                      key={file.id}
                      className="flex flex-row items-center justify-between rounded border border-slate-200 p-2 pr-5"
                    >
                      <div className="flex flex-row justify-start gap-3">
                        <div className="micro-copy rounded bg-slate-400 p-3 text-black-text shadow">
                          {file.fileType}
                        </div>
                        <div className="flex flex-col">
                          <p className="heading-card-title text-black-text">
                            {file.name}
                          </p>
                          <span className="micro-copy text-shadow-text">
                            {file.size}
                          </span>
                        </div>
                      </div>
                      <BiTrash
                        onClick={() => {
                          removeSelectedAttachment(file.id);
                        }}
                        className="cursor-pointer text-xl hover:text-red-alert"
                      />
                    </div>
                  ))}

                  <Dropzone
                    multiple={true}
                    maxSize={10000000}
                    onDrop={onDropFile}
                    onDropRejected={onDropRejected}
                  >
                    <div className="flex cursor-pointer flex-row items-center justify-between rounded border-2 border-dashed border-shadow-text p-2 pr-5 shadow hover:border-blue-light hover:text-blue-light">
                      <div className="flex flex-row items-center justify-start gap-3">
                        <div className="micro-copy rounded bg-slate-400 p-3 text-black-text shadow">
                          pdf
                        </div>
                        <div className="flex flex-col">
                          <p className="heading-card-title text-black-text">
                            Drop files here
                          </p>
                          <span className="micro-copy text-shadow-text">
                            Supported files: docx, pptx, pdf, jpg or png,
                            maximum file size is 10 MB.
                          </span>
                        </div>
                      </div>
                      <AiOutlineCloudUpload className="text-2xl" />
                    </div>
                  </Dropzone>
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} variant="outlined" sx={outlinedButton}>
                Cancel
              </Button>
              <Button type="submit" variant="contained" sx={containedButton}>
                Submit
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      )}
    </Formik>
  );
};

export default PrivateChatModal;
