import * as yup from "yup";
import { challengeStates } from "../../../../@consts/state";

export const ChallengeInitialValue: ChallengeForm = {
  title: "",
  overview: "",
  details: "",
  awardAmount: 0,
  endDate: new Date(),
  industryGroupSector: "",
  disciplines: "",
  specificTermsAndConditions: "",
  state: challengeStates.draft,
  isPublic: true,
  criterias: [
    {
      label: "Budget",
      percentage: 0,
    },
    {
      label: "Feasibility",
      percentage: 0,
    },
    {
      label: "Implementation",
      percentage: 0,
    },
  ],
};

export interface ChallengeCriteriaForm {
  label: string;
  percentage: number;
}

export interface ChallengeForm {
  id?: string;
  titleSlug?: string;
  title: string;
  overview: string;
  details: string;
  awardAmount: number;
  startDate?: Date;
  endDate: Date;
  industryGroupSector: string;
  disciplines: string;
  specificTermsAndConditions: string;
  state: string;
  imageId?: string;
  qualifyingCriteria?: string;
  keywords?: string;
  isPublic: boolean;
  criterias: ChallengeCriteriaForm[];
}

// export interface ChallengeForm extends yup.InferType<typeof ChallengeSchema> {}

export const ChallengeSchema = yup.object().shape({
  title: yup.string().max(250).required("Required"),
  overview: yup.string().max(250).required("Required"),
  details: yup.string().required("Required"),
  awardAmount: yup
    .number()
    .positive("Challenge reward must be positive number amount")
    .integer()
    .required("Required"),
  endDate: yup.date().required("Required"),
  industryGroupSector: yup.string().required("Required"),
  disciplines: yup.string().required("Required"),
  specificTermsAndConditions: yup.string().required("Required"),
  state: yup
    .string()
    .oneOf(["DRAFT", "PENDING_APPROVAL"], "Invalid State Type")
    .required("Required"),
  isPublic: yup.boolean().required("Required"),
  criterias: yup
    .array()
    .of(
      yup.object().shape({
        label: yup.string().required("Required"),
        percentage: yup
          .number()
          .positive()
          .integer()
          .min(0)
          .max(100)
          .required("Required"),
      }),
    )
    .min(3)
    .max(5),
});
