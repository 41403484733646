// Libraries
import { axiosPrivate } from "./axios";

// Constants
const baseURL = process.env.REACT_APP_BACKEND_SERVICE_URI;

export const getGeneratedExcelFileOfUsersList = async () => {
  const accessToken = localStorage.getItem("accessToken") || "null";

  const result = await fetch(`${baseURL}generates/users`, {
    credentials: "include",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  const blob = await result.blob();

  return blob;
};
