// Custom Third Party Libraries
import { axiosPrivate } from "./axios";

// Types
export type readNotificationType = {
  userId: string;
  notificationId: string;
};

export type NotificationsFilter = {
  userId: string;
  isRead?: boolean | string;
};

// Constants
const baseURL = process.env.REACT_APP_BACKEND_SERVICE_URI;

export const getUserNotifications = async ({
  userId,
  isRead = "",
}: NotificationsFilter) => {
  return await axiosPrivate.get(
    `/users/${userId}/notifications?isRead=${isRead}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    }
  );
};

export const readNotification = async (values: readNotificationType) => {
  const accessToken = localStorage.getItem("accessToken") || "null";

  const result = await fetch(
    `${baseURL}users/${values.userId}/notifications/${values.notificationId}`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  if (!result.ok) {
    throw new Error("Network response was not ok");
  }

  const json = await result.json();

  return json;
};
