// Third Parties
import ScrollToTop from "react-scroll-to-top";

// Third Party Types
import { GoArrowUp } from "react-icons/go";

const XScrollToTop = () => {
  return (
    <ScrollToTop
      smooth
      component={
        <p className="text-blue-light text-3xl pt-1">
          <GoArrowUp />
        </p>
      }
    />
  );
};

export default XScrollToTop;
