// System
import React, { useState } from "react";

// Third Party Types
import { Button, TextField } from "@mui/material";
import { Link } from "react-router-dom";

type Props = {};

const ForgotPassword = (props: Props) => {
  const [email, setEmail] = useState("");

  //state validation
  const [validation, setValidation] = useState([]);

  const forgotPassword = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    console.log("forgot password");
  };

  return (
    <>
      <h1>Reset password</h1>
      <p className="mt-2 heading-card-title">
        Enter your email address and we will send you a link to reset your
        password.
      </p>
      <div className="mt-8">
        <form onSubmit={forgotPassword} className="flex flex-col">
          <label htmlFor="email" className="text">
            Email Address<span className="text-orange-alt">*</span>
          </label>
          <TextField
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            variant="standard"
            type="email"
            name="email"
            id="email"
            placeholder="Your registerd email address"
            className="mt-4"
          />

          <div className="mt-8 flex flex-row justify-between">
            <Button type="submit" className="btn btn-primary">
              Reset password
            </Button>
            <Link
              to={"/login"}
              className="no-underline font-semibold text-blue-mid"
            >
              Back to sign in
            </Link>
          </div>
        </form>
      </div>
    </>
  );
};

export default ForgotPassword;
