import * as yup from "yup";
import { ROLES } from "../../../../@consts/roles";

const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/;
// min 5 characters, 1 upper case letter, 1 lower case letter, 1 numeric digit.

export const RegisterInitialValue = {
  userName: "",
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  confirmPassword: "",
  companyName: "",
  country: "",
  industryGroupSector: "",
  disciplines: "",
  checked: false,
  role: ROLES.SEEKER,
};

export interface RegisterForm {
  userName: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
  companyName: string;
  country: string;
  industryGroupSector: string;
  disciplines: string;
  checked: boolean;
  role?: string;
  assessorId?: string;
}

export const RegisterSchema = yup.object().shape({
  userName: yup
    .string()
    .min(3, "Username must be at least 3 characters long")
    .required("Required"),
  firstName: yup.string().required("Required"),
  lastName: yup.string().required("Required"),
  email: yup.string().email("Please enter a valid email").required("Required"),
  password: yup
    .string()
    .min(5)
    .matches(passwordRules, { message: "Please create a stronger password" })
    .required("Required"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Required"),
  companyName: yup.string().required("Required"),
  country: yup.string().required("Required"),
  industryGroupSector: yup.string().required("Required"),
  disciplines: yup.string().required("Required"),
  checked: yup.boolean().oneOf([true], "Accept the terms and conditions"),
  assessorId: yup.string().optional(),
});
