// System
import { useCallback, useEffect, useState } from "react";

// Third Party Type Imports
import { AiOutlineCloudUpload } from "react-icons/ai";
import { BiTrash } from "react-icons/bi";
import { FileRejection } from "react-dropzone";
import { Formik, FormikHelpers } from "formik";
import {
  Link,
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { QueryClient, useMutation, useQuery } from "@tanstack/react-query";
import { AlertColor, Button, FormHelperText, TextField } from "@mui/material";

// API
import {
  getChallengeDetails,
  getChallengeBanner,
} from "../../../api/challenge";
import {
  createNewSolution,
  editSolution,
  getSolutionDetails,
} from "../../../api/solution";
import {
  createNewFile,
  createNewAttachment,
  getAttachments,
  getFile,
  removeAttachment,
} from "../../../api/file";

// Contexts
import { useSnackbarContext } from "../../../contexts/SnackbarContextProvider";

// Custom Hooks
import useLogout from "../../../hooks/useLogout";
import useSnackbar from "../../../hooks/useSnackbar";

// Components
import {
  ChallengeHero,
  CommonCard,
  Dropzone,
  Editor,
  Spinner,
  XSnackbar,
} from "../../../components";

// Utils
import { formatFileName, getExtension, getFileSize } from "../../../utils/file";

// Schemas
import {
  SolutionInitialValue,
  SolutionForm,
  SolutionSchema,
} from "./consts/schemas";

// Constants
import { ALERT } from "../../../@consts/alert";

// Types
import { AttachmentInterface } from "../../../@types/attachment";
import { ErrorInterface } from "../../../@types/response";
import { FileInterface } from "../../../@types/file";
import { solutionStates } from "../../../@consts/state";
import { SnackbarContextType } from "../../../@types/snackbarContext";
import { SolutionInterface } from "../../../@types/solution";

type LocationState = {
  challengeId?: string;
};

const AddSolution = () => {
  // Libraries
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const queryClient = new QueryClient();

  // Constants
  const challengeId =
    params.challengeId || (location.state as LocationState)?.challengeId;
  const solutionId = params.solutionId;

  // Hooks
  const logout = useLogout();
  const snackbar = useSnackbar();

  // Contexts
  const { openSnackbar, setOpenSnackbar, alertType, alertBody } =
    useSnackbarContext() as SnackbarContextType;

  // Data State
  const [solutions, setSolutions] = useState<SolutionInterface>();
  const [attachments, setAttachments] = useState<FileInterface[]>([]);

  // UI State
  const [isLoading, setIsLoading] = useState(true);
  const [isEditMode, setIsEditMode] = useState(false);

  // Components State
  const [isAssignedEvaluator, setIsAssignedEvaluator] = useState(false);

  // Events
  const handleAlert = (alertType: string, alertBody: string) =>
    snackbar(alertType, alertBody);

  const handleValidation = (formIsValid: boolean) => {
    if (!formIsValid) {
      snackbar(ALERT.error, "Validation error");
    }
  };

  const onDropFile = useCallback((acceptedFiles: File[]) => {
    acceptedFiles.forEach((file) => {
      const fd = new FormData();
      fd.append("file", file, file.name);

      uploadAttachmentMutation.mutate(fd);
    });
  }, []);

  const onDropRejected = useCallback((fileRejections: FileRejection[]) => {
    fileRejections.forEach((file) => {
      const errors: string[] = file.errors.map(({ message }) => message);

      const errorMessage: string = `${file.file.name} rejected. ${errors.join(
        ", "
      )}`;

      snackbar(ALERT.error, errorMessage);
    });
  }, []);

  const handleSubmitAttachments = (objectId: string) => {
    attachments.map((attachment) => {
      submitAttachmentMutation.mutate({
        objectId,
        objectType: "SOLUTION",
        fileId: attachment.id,
      });
    });
  };

  const removeSelectedAttachment = (id: string) => {
    if (isEditMode) {
      removeAttachmentMutation.mutate(id);
    }

    setAttachments((current) =>
      current.filter((file) => {
        return file.id !== id;
      })
    );
  };

  // Form Submit
  const handleSubmitForm = async (
    values: SolutionForm,
    actions: FormikHelpers<SolutionForm>
  ) => {
    isEditMode ? updateSolution(values) : submitSolution(values);

    actions.setSubmitting(false);
  };

  const submitSolution = (values: SolutionForm) => {
    submitSolutionMutation.mutate({
      ...values,
      challengeId: challengeId,
    });
  };

  const updateSolution = (values: SolutionForm) => {
    updateSolutionMutation.mutate({
      ...values,
      challengeId: challengeId,
    });
  };

  // Mutation
  const updateSolutionMutation = useMutation(editSolution, {
    onSuccess(data) {
      navigate("/user/solutions", {
        state: {
          showAlert: true,
          alertType: ALERT.success,
          alertBody: "Solution updated successfully",
        },
        replace: true,
      });
    },
  });

  const submitSolutionMutation = useMutation(createNewSolution, {
    onSuccess(data) {
      handleSubmitAttachments(data.data.id);

      navigate("/user/solutions", {
        state: {
          showAlert: true,
          alertType: ALERT.success,
          alertBody: "New solution added successfully",
        },
        replace: true,
      });
    },
    onError: (err: ErrorInterface) => {
      if (err.response.status === 401) {
        const unauthorized = err.response.status;

        logout(unauthorized);
      }

      const alertType = ALERT.error;
      const alertBody = err.response.data?.message || err.message;

      handleAlert(alertType, alertBody);
    },
  });

  const submitAttachmentMutation = useMutation(createNewAttachment, {
    onSuccess(data, variables) {
      if (isEditMode) {
        handleAttachmentStateinEditMode(variables.fileId, data.data.id);

        const alertType = ALERT.success;
        const alertBody = "Attachment added successfully";

        handleAlert(alertType, alertBody);
      }
    },
    onError(error, variables, context) {},
  });

  const uploadAttachmentMutation = useMutation(createNewFile, {
    onSuccess(data, variables) {
      const file = variables.get("file") as File;

      const attachment = {
        name: file.name,
        fileType: getExtension(file.name),
        size: getFileSize(file.size),
        id: data.data.dataValues.id,
      };

      setAttachments((prev) => [...prev, { ...attachment }]);

      if (isEditMode) {
        submitAttachmentMutation.mutate({
          objectId: params.solutionId!,
          objectType: "SOLUTION",
          fileId: data.data.dataValues.id,
        });
      }
    },
    onError: (err: ErrorInterface) => {
      if (err.response.status === 401) {
        const unauthorized = err.response.status;

        logout(unauthorized);
      }

      const alertType = ALERT.error;
      const alertBody = err.response.data?.message || err.message;

      handleAlert(alertType, alertBody);
    },
  });

  const removeAttachmentMutation = useMutation(removeAttachment, {
    onSuccess() {
      const alertType = ALERT.success;
      const alertBody = "Attachment remove successfully";

      handleAlert(alertType, alertBody);
    },
    onError: (err: ErrorInterface) => {
      if (err.response.status === 401) {
        const unauthorized = err.response.status;

        logout(unauthorized);
      }

      const alertType = ALERT.error;
      const alertBody = err.response.data?.message || err.message;

      handleAlert(alertType, alertBody);
    },
  });

  const handleAttachmentStateinEditMode = (
    fileId: string,
    attachmentId: string
  ) => {
    setAttachments((prevState) => {
      const newState = prevState.map((obj) => {
        if (obj.id === fileId) {
          return { ...obj, id: attachmentId };
        }

        return obj;
      });

      return newState;
    });
  };

  const handleEvaluatorPrevilege = (isAssignedEvaluator: boolean) => {
    if (!isAssignedEvaluator) return;

    setIsAssignedEvaluator(true);
  };

  // Data Fetching
  const challenge = useQuery(
    ["challenge", challengeId],
    () => getChallengeDetails(challengeId!),
    {
      onSuccess(data) {
        handleEvaluatorPrevilege(data.data.isAssignedEvaluator);
      },
      onError(err: ErrorInterface) {
        if (err.response.status === 401) {
          const unauthorized = err.response.status;

          logout(unauthorized);
        }
      },
    }
  );

  const fetchSolution = async (solutionId: string) => {
    try {
      const solution = await queryClient.fetchQuery(
        ["solution", solutionId],
        async () => getSolutionDetails(solutionId)
      );

      setSolutions(solution.data);

      fetchAttachments(solution.data.id);
    } catch (err: any) {
      if (err.response.status === 401) {
        const unauthorized = err.response.status;

        logout(unauthorized);
      }

      const alertType = ALERT.error;
      const alertBody = err.response.data?.message || err.message;

      handleAlert(alertType, alertBody);
    } finally {
      setIsEditMode(true);
      setIsLoading(false);
    }
  };

  const fetchAttachments = async (objectId: string) => {
    try {
      const attachments = await queryClient.fetchQuery(
        ["challenge-attachments", objectId],
        async () => getAttachments(objectId)
      );

      attachments.data.map((attachment: AttachmentInterface) => {
        (async () => {
          const file = await fetchFile(attachment.fileId);

          const next: FileInterface = {
            name: attachment.fileName,
            fileType: getExtension(attachment.fileName),
            size: getFileSize(file?.size!),
            id: attachment.id,
          };

          setAttachments((prev) => [...prev, { ...next }]);
        })();
      });
    } catch (error) {}
  };

  const fetchFile = async (fileId: string) => {
    try {
      const file = await queryClient.fetchQuery(["file", fileId], async () =>
        getFile(fileId)
      );

      return file;
    } catch (error) {}
  };

  // Effects
  useEffect(() => {
    const setFormValues = async (solutionId: string) => {
      await fetchSolution(solutionId);
    };

    !params.solutionId ? setIsLoading(false) : setFormValues(params.solutionId);
  }, []);

  // JSX
  if (isLoading) {
    return <Spinner marginTop />;
  }

  if (challenge.isLoading) {
    return <Spinner marginTop />;
  }

  if (isAssignedEvaluator) {
    return <Navigate to="../.." replace />;
  }

  if (params.solutionId !== undefined) {
    if (solutions?.state !== solutionStates.draft) {
      return (
        <Navigate
          to="/user/solutions"
          state={{
            showAlert: true,
            alertType: ALERT.error,
            alertBody: "You already have submitted solution",
          }}
          replace
        />
      );
    }
  } else if (challenge.data?.data.userSolutionsForChallenge !== null) {
    return (
      <Navigate
        to="/user/solutions"
        state={{
          showAlert: true,
          alertType: ALERT.error,
          alertBody: "You already have saved solution",
        }}
        replace
      />
    );
  }

  return (
    <>
      <ChallengeHero
        id={challenge.data?.data.id}
        title={challenge.data?.data.title}
        state={challenge.data?.data.state}
        challengeId={challenge.data?.data.id}
      />
      <div className="flex flex-col xl:flex-row justify-between gap-5">
        <div className="basis-3/4">
          <CommonCard title="Propose a solution">
            <Formik
              initialValues={
                {
                  ...SolutionInitialValue,
                  ...solutions,
                } as SolutionForm
              }
              validationSchema={SolutionSchema}
              onSubmit={handleSubmitForm}
              validateOnMount={true}
            >
              {(props) => (
                <form
                  onSubmit={props.handleSubmit}
                  autoComplete="off"
                  className="flex flex-col"
                >
                  <label htmlFor="title" className="text">
                    Proposed solution title
                    <span className="text-orange-alt">*</span>
                  </label>
                  <TextField
                    value={props.values.title}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    error={
                      props.errors.title && props.touched.title ? true : false
                    }
                    helperText={
                      props.errors.title && props.touched.title
                        ? props.errors.title
                        : ""
                    }
                    variant="standard"
                    id="title"
                    name="title"
                    type="text"
                    placeholder="Provide your title solution"
                    className="mt-4"
                  />

                  <label htmlFor="details" className="text mt-4">
                    Solution details
                    <span className="text-orange-alt">*</span>
                  </label>
                  <div className="mt-1 border border-shadow-text rounded">
                    <Editor
                      defaultValue={solutions?.details}
                      onChange={(context) => {
                        props.setFieldValue("details", context);
                      }}
                    />
                  </div>
                  {props.errors.details && props.touched.details && (
                    <FormHelperText error>
                      {props.errors.details}
                    </FormHelperText>
                  )}

                  <label htmlFor="" className="text mt-4">
                    Supporting document
                  </label>

                  <div className="mt-2 p-3 flex flex-col h-fit rounded-[3px] border border-slate-300">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      {attachments.map((file) => (
                        <div
                          key={file.id}
                          className="flex flex-row rounded border border-slate-200 p-2 pr-5 justify-between items-center"
                        >
                          <div className="flex flex-row justify-start gap-3">
                            <div className="bg-slate-400 rounded shadow p-3 micro-copy text-black-text">
                              {file.fileType}
                            </div>
                            <div className="flex flex-col">
                              <p className="heading-card-title text-black-text">
                                {formatFileName(file.name)}
                              </p>
                              <span className="micro-copy text-shadow-text">
                                {file.size}
                              </span>
                            </div>
                          </div>
                          <BiTrash
                            onClick={() => {
                              removeSelectedAttachment(file.id);
                            }}
                            className="text-xl hover:text-red-alert cursor-pointer"
                          />
                        </div>
                      ))}

                      <Dropzone
                        multiple={true}
                        maxSize={10000000}
                        onDrop={onDropFile}
                        onDropRejected={onDropRejected}
                      >
                        <div className="flex flex-row rounded hover:text-blue-light hover:border-blue-light cursor-pointer border-2 border-dashed border-shadow-text shadow p-2 pr-5 justify-between items-center">
                          <div className="flex flex-row justify-start items-center gap-3">
                            <div className="bg-slate-400 rounded shadow p-3 micro-copy text-black-text">
                              pdf
                            </div>
                            <div className="flex flex-col">
                              <p className="heading-card-title text-black-text">
                                Drop files here
                              </p>
                              <span className="micro-copy text-shadow-text">
                                Supported files: docx, pptx, pdf, jpg or png,
                                maximum file size is 10 MB.
                              </span>
                            </div>
                          </div>
                          <AiOutlineCloudUpload className="text-2xl" />
                        </div>
                      </Dropzone>
                    </div>
                  </div>

                  <div className=" mt-8 flex flex-row items-center justify-between">
                    <div>
                      <Button
                        disabled={props.isSubmitting}
                        onClick={() => {
                          props.setFieldValue(
                            "state",
                            solutionStates.submitted
                          );

                          handleValidation(props.isValid);
                        }}
                        type="submit"
                        className="btn btn-primary disabled:text-white disabled:opacity-75"
                      >
                        Submit Solution
                      </Button>
                      <Button
                        disabled={props.isSubmitting}
                        onClick={() => {
                          props.setFieldValue("state", solutionStates.draft);

                          handleValidation(props.isValid);
                        }}
                        type="submit"
                        className="ml-5 btn btn-primary-outline"
                      >
                        Save as Draft
                      </Button>
                    </div>
                    <Link to={"../../"} className="no-underline link">
                      Go back
                    </Link>
                  </div>
                </form>
              )}
            </Formik>
          </CommonCard>
        </div>
        <div className="basis-1/4">
          <CommonCard title="Challenge overview">
            {challenge.data?.data.overview}
          </CommonCard>
        </div>
      </div>

      {openSnackbar && (
        <XSnackbar
          open={openSnackbar}
          onClose={() => {
            setOpenSnackbar(false);
          }}
          alertType={alertType as AlertColor}
          alertBody={alertBody}
        />
      )}
    </>
  );
};

export default AddSolution;
