import { industryOptions, IndustryOptionType } from "../@consts/industry";

export const industriesToString = (arrays: IndustryOptionType[]) => {
  if (arrays.length === 0) {
    return "";
  }

  let array: string[] = [];

  array = arrays.map(({ title }) => title);

  return array.join(", ");
};

export const industriesToArray = (
  industriesString: string
): IndustryOptionType[] => {
  let returnArray: IndustryOptionType[] = [];

  let array = industriesString.split(", ");

  array.map((value) => {
    const index = industryOptions.map((e) => e.title).indexOf(value);

    index !== -1 && returnArray.push(industryOptions[index]);
  });

  return returnArray;
};
