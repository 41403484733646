import moment from "moment";

export const daysRemaining = (endDate: string) => {
  let given = moment(endDate, "YYYY-MM-DD");
  let current = moment().startOf("day");

  const result = moment.duration(given.diff(current)).asDays();

  if (result === 0) {
    return 1;
  } else if (result < 0) {
    return 0;
  }

  return result;
};
