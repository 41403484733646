// System
import { ChangeEvent } from "react";

// Modules
import { Checkbox, Divider, FormControlLabel, FormGroup } from "@mui/material";

// Constants
import { industryOptions } from "../../@consts/industry";
import { disciplineOptions } from "../../@consts/discipline";

// Types
type Props = {
  industryChecked: string[];
  handleChangeIndustry: (e: ChangeEvent<HTMLInputElement>) => void;
  disciplineChecked: string[];
  handleChangeDiscipline: (e: ChangeEvent<HTMLInputElement>) => void;
  handleClearFilter: () => void;
};

const Filter = ({
  industryChecked,
  handleChangeIndustry,
  disciplineChecked,
  handleChangeDiscipline,
  handleClearFilter,
}: Props) => {
  return (
    <div className="nav-item absolute right-0 top-16 w-80">
      <div className="grid grid-cols-1 divide-shadow-text w-full bg-white rounded shadow-lg">
        <div
          id="headerCard"
          className="px-5 py-3 flex flex-row justify-between"
        >
          <p className="text-comment">Filters</p>
        </div>
        <Divider />
        <div className="p-3 max-h-96 overflow-auto">
          <label
            htmlFor="industryGroupSector"
            className="text-comment font-bold"
          >
            Industry group
          </label>
          <div className="mb-2"></div>
          <FormGroup className="px-5">
            {industryOptions.map(({ title }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    id="industryGroupSector"
                    size="small"
                    checked={industryChecked.some((n) => n === title)}
                    value={title}
                    onChange={handleChangeIndustry}
                  />
                }
                label={<label className="text-comment">{title}</label>}
              />
            ))}
          </FormGroup>

          <div className="mt-4"></div>
          <label className="text-comment font-bold">Disciplines</label>
          <div className="mb-2"></div>
          <FormGroup className="px-5">
            {disciplineOptions.map(({ title }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={disciplineChecked.some((n) => n === title)}
                    value={title}
                    onChange={handleChangeDiscipline}
                  />
                }
                label={<label className="text-comment">{title}</label>}
              />
            ))}
          </FormGroup>
        </div>
        <div className="mt-auto px-5 py-2 grid justify-items-start w-full">
          <p
            className="text-comment text-[12px] cursor-pointer text-blue-light hover:text-blue-deep"
            onClick={handleClearFilter}
          >
            Clear all
          </p>
        </div>
      </div>
    </div>
  );
};

export default Filter;
