// System
import { ChangeEvent, useEffect, useState } from "react";

// Modules
import { Button, ClickAwayListener, Pagination } from "@mui/material";
import { CgSortAz } from "react-icons/cg";
import { HiPlus } from "react-icons/hi";
import { Link, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

// API
import { ChallengesFilter, getOpenChallenges } from "../../../api/challenge";

// Custom Hooks
import useLogout from "../../../hooks/useLogout";

// Components
import {
  ChallengeCard,
  ContentHeader,
  Filter,
  Spinner,
} from "../../../components";

// Utils
import { deformatCountry } from "../../../utils/country";

// Types
import { ChallengeInterface } from "../../../@types/challenge";
import { ErrorInterface } from "../../../@types/response";

const Challenges = () => {
  // Libraries
  const params = useParams();

  // Constants
  const country = params.country;

  // Hooks
  const logout = useLogout();

  // Components State
  const [filter, setFilter] = useState<ChallengesFilter>({});

  const [count, setCount] = useState(0);
  const [perPage, setPerPage] = useState(12);
  const [currentPage, setCurrentPage] = useState(1);

  const begin = (currentPage - 1) * perPage;
  const end = begin + perPage;

  const [openFilter, setOpenFilter] = useState(false);
  const [industryChecked, setIndustryChecked] = useState<string[]>([]);
  const [disciplineChecked, setDisciplineChecked] = useState<string[]>([]);

  // Events
  const getHeader = (country?: string) => {
    return !country ? "Open Challenges" : deformatCountry(country);
  };

  const handleClick = () => {
    setOpenFilter((prev) => !prev);
  };

  const handleClickAway = () => {
    setOpenFilter(false);
  };

  const handleChangeIndustry = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.checked) {
      setIndustryChecked((current) =>
        current.filter((industry) => {
          return industry !== e.target.value;
        })
      );
    } else {
      setIndustryChecked((prev) => [...prev, e.target.value]);
    }

    setCurrentPage(1);
    challenges.refetch();
  };

  const handleChangeDiscipline = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.checked) {
      setDisciplineChecked((current) =>
        current.filter((discipline) => {
          return discipline !== e.target.value;
        })
      );
    } else {
      setDisciplineChecked((prev) => [...prev, e.target.value]);
    }

    setCurrentPage(1);
    challenges.refetch();
  };

  const handleClearFilter = () => {
    setIndustryChecked([]);
    setDisciplineChecked([]);

    challenges.refetch();
  };

  // Data Fetching
  const challenges = useQuery(
    ["challenges", filter],
    () => getOpenChallenges(filter),
    {
      onSuccess(data) {
        setCount(Math.ceil(data.data.length / perPage));
      },
      onError(err: ErrorInterface) {
        if (err.response.status === 401) {
          const unauthorized = err.response.status;

          logout(unauthorized);
        }
      },
      select(data) {
        const filteredData = data.data.filter((x: ChallengeInterface) => {
          const handleFilterIndustry = () => {
            if (industryChecked.length < 1) {
              return true;
            } else {
              let industryFiltered;
              let industryArr = x.industryGroupSector?.split(", ");
              industryArr?.forEach((value) => {
                industryFiltered = industryChecked.some(
                  (checked) => checked === value
                );
              });
              if (industryFiltered === true) {
                return true;
              }
            }
          };

          const handleFilterDiscipline = () => {
            if (disciplineChecked.length < 1) {
              return true;
            } else {
              let disciplineFiltered;
              let disciplineArr = x.disciplines?.split(", ");
              disciplineArr?.forEach((value) => {
                disciplineFiltered = disciplineChecked.some(
                  (checked) => checked === value
                );
              });
              if (disciplineFiltered === true) {
                return true;
              }
            }
          };

          if (handleFilterIndustry() && handleFilterDiscipline()) {
            return true;
          }
        });

        data.data = filteredData;

        return data;
      },
    }
  );

  useEffect(() => {
    !country ? setFilter({}) : setFilter({ country: deformatCountry(country) });
  }, [country]);

  return (
    <>
      <div
        id="header"
        className="flex flex-col md:flex-row md:justify-between gap-y-4"
      >
        <div className="flex flex-col">
          <ContentHeader h1={getHeader(country)} />
        </div>

        <div className="flex flex-row justify-between items-center gap-6">
          <Link to={"/challenges/add"} className="no-underline">
            <Button startIcon={<HiPlus />} className="btn btn-primary">
              Create new challenge
            </Button>
          </Link>

          <ClickAwayListener onClickAway={handleClickAway}>
            <div className="relative">
              <Button
                onClick={handleClick}
                startIcon={<CgSortAz />}
                className="normal-case text-black-text font-[400px] text-[16px] leading-[20px] hover:bg-transparent hover:shadow-sm"
              >
                Filter by
              </Button>

              {openFilter ? (
                <Filter
                  industryChecked={industryChecked}
                  handleChangeIndustry={handleChangeIndustry}
                  disciplineChecked={disciplineChecked}
                  handleChangeDiscipline={handleChangeDiscipline}
                  handleClearFilter={handleClearFilter}
                />
              ) : null}
            </div>
          </ClickAwayListener>
        </div>
      </div>
      {challenges.isLoading ? (
        <Spinner marginTop />
      ) : !challenges.data?.data.length ? (
        <div className="flex flex-col w-full justify-center items-center mt-10">
          <p className="text text-gray-500 mt-4">No open challenges posted</p>
          <div className="mt-2">
            <Link to={"/challenges/add"} className="mt-8 no-underline link">
              Create new challenge
            </Link>
          </div>
        </div>
      ) : (
        <>
          <div className="mt-5 grid grid-cols-1 sm:grid-cols-2 xl:sm:grid-cols-4 gap-5">
            {challenges.data?.data
              .slice(begin, end)
              .map((challenge: ChallengeInterface) => (
                <ChallengeCard
                  key={challenge.id}
                  id={challenge.id}
                  imageId={challenge.imageId}
                  title={challenge.title}
                  state={challenge.state}
                  awardAmount={challenge.awardAmount}
                  userId={challenge.userId}
                  userName={challenge.userName}
                  endDate={challenge.endDate}
                />
              ))}
          </div>

          {count > 1 && (
            <div className="mt-5 flex justify-start">
              <Pagination
                shape="rounded"
                count={count}
                onChange={(e, p) => {
                  setCurrentPage(p);
                }}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Challenges;
