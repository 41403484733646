import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";

import { outlinedButton } from "../../@styles/outlinedButton";
import { EvaluatorScoreInterface } from "../../@types/evaluatorScore";
import { getEvaluatorScore } from "../../api/evaluator";
import EvaluatorScore from "../EvaluatorScore/EvaluatorScore";
import ScoreListAndChat from "../ScoreListAndChat/ScoreListAndChat";

type Props = {
  open: boolean;
  onClose: () => void;
  solutionId: string;
};

const Evaluation = ({ open, onClose, solutionId }: Props) => {
  const [clickedEvaluator, setClickedEvaluator] = useState<string | null>(null);
  const [clickedSection, setClickedSection] = useState<"score" | "chat">(
    "chat",
  );
  const evaluatorScore = useQuery(["evaluator-score", solutionId], () =>
    getEvaluatorScore(solutionId ?? ""),
  );

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xl" fullWidth>
      <DialogTitle id="alert-dialog-title">
        <div className="flex flex-row gap-x-5">
          <div className="w-1/2">
            <h2>View Evaluation</h2>
            <span className="text-sm text-black-text">
              Select multiple user to assign to this company
            </span>
          </div>
          <div className="w-1/2">
            <h2 className="text-[#2F6CAF]">Score List of criteria</h2>
            <span className="text-sm text-black-text">
              This is the list of evaluator score
            </span>
          </div>
        </div>
      </DialogTitle>
      <DialogContent dividers>
        <div className="flex flex-row gap-x-5">
          <div className="flex w-1/2 flex-col gap-y-3">
            {evaluatorScore.data?.data.map(
              ({
                evaluatorId,
                evaluatorName,
                totalScore,
              }: EvaluatorScoreInterface) => (
                <EvaluatorScore
                  evaluatorId={evaluatorId}
                  evaluatorName={evaluatorName}
                  totalScore={totalScore}
                  onFocus={clickedEvaluator}
                  setOnFocus={setClickedEvaluator}
                />
              ),
            )}
          </div>
          <ScoreListAndChat
            clickedSection={clickedSection}
            setClickedSection={setClickedSection}
            scoreList={
              !clickedEvaluator
                ? []
                : evaluatorScore.data?.data.find(
                    ({ evaluatorId }: EvaluatorScoreInterface) =>
                      evaluatorId === clickedEvaluator,
                  ).scoreList
            }
            solutionId={solutionId}
            evaluatorId={clickedEvaluator}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined" sx={outlinedButton}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Evaluation;
