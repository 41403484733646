// Third Party Type Imports
import { Navigate, Outlet, useLocation } from "react-router-dom";

// Contexts
import { useAuthContext } from "../../contexts/AuthContextProvider";

// Types
import { AuthContextType } from "../../@types/authContext";

type Props = {
  allowedRole: string[];
};

const AuthentificationRoute = ({ allowedRole }: Props) => {
  const { auth } = useAuthContext() as AuthContextType;

  const location = useLocation();

  return allowedRole.includes(auth?.role!) ? (
    <Outlet />
  ) : auth ? (
    <Navigate to="/unathorized" state={{ from: location }} replace />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default AuthentificationRoute;
