// Custom Third Party Libraries
import axios from "./axios";

// Schemas
import { RegisterForm } from "../pages/Authentification/Register/consts/schemas";
import { SignInForm } from "../pages/Authentification/SignIn/consts/schemas";

interface VerifyParamsType {
  email: string | null;
  token: string | null;
}

export const register = async (values: RegisterForm) => {
  return await axios.post("/users/register", values);
};

export const signIn = async (values: SignInForm) => {
  return await axios.post("/users/authenticate", values, {
    withCredentials: true,
  });
};

export const verify = async (values: VerifyParamsType) => {
  return await axios.get(
    `/users/verify?email=${values.email}&token=${values.token}`
  );
};
