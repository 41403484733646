// Assets
import manuVateBackground from "./assets/images/manuvate-background-v2.png";
import manuVateLogoWhite from "./assets/images/manuvate-logo-white.png";

const AuthentificationSideBackground = () => {
  return (
    <div
      className="flex-col bg-cover bg-bottom p-12 h-screen w-1/2 fixed"
      style={{ backgroundImage: `url(${manuVateBackground}` }}
    >
      <img src={manuVateLogoWhite} alt="ManuVate" className="w-96 h-16" />
      <div className="mt-6 pl-4 flex flex-col gap-2">
        <h1 className="text-white">Drive Change and Innovate.</h1>
        <h1 className="text-white">Brainstorm Ideas.</h1>
        <h1 className="text-white">Solve Challenges.</h1>
      </div>
    </div>
  );
};

export default AuthentificationSideBackground;
