// Custom Third Party Libraries
import { axiosPrivate } from "./axios";

export const getDashboardDetails = async (userId: string) => {
  return await axiosPrivate.get(`/users/${userId}/dashboard-details`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    },
  });
};
