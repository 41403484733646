import { disciplineOptions, DisciplineOptionType } from "../@consts/discipline";

export const disciplinesToString = (arrays: DisciplineOptionType[]) => {
  if (arrays.length === 0) {
    return "";
  }

  let array: string[] = [];

  array = arrays.map(({ title, inputValue }) => {
    if (!inputValue) {
      return title;
    }

    return inputValue;
  });

  return array.join(", ");
};

export const disciplinesToArray = (
  disciplesString: string
): DisciplineOptionType[] => {
  let returnArray: DisciplineOptionType[] = [];

  let array = disciplesString.split(", ");

  array.map((value) => {
    const index = disciplineOptions.map((e) => e.title).indexOf(value);

    if (index === -1) {
      returnArray.push({ title: value });
    } else {
      returnArray.push(disciplineOptions[index]);
    }
  });

  return returnArray;
};
