// System
import { createContext, ReactNode, useContext, useState } from "react";

// Modules
import { AlertColor } from "@mui/material";

// Types
import { SnackbarContextType } from "../@types/snackbarContext";

type Props = {
  children: ReactNode;
};

// Constants
const SnackbarContext = createContext<SnackbarContextType | null>(null);

export const SnackbarProvider = ({ children }: Props) => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertType, setAlertType] = useState<AlertColor | undefined>(undefined);
  const [alertBody, setAlertBody] = useState("");

  return (
    <SnackbarContext.Provider
      value={{
        openSnackbar,
        setOpenSnackbar,
        alertType,
        setAlertType,
        alertBody,
        setAlertBody,
      }}
    >
      {children}
    </SnackbarContext.Provider>
  );
};
export const useSnackbarContext = () => useContext(SnackbarContext);
