// Modules
import { Avatar, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

// Contexts
import { useStateContext } from "../../contexts/ContextProvider";

// Custom Hooks
import useLogout from "../../hooks/useLogout";

// Utils
import { stringAvatar } from "../../utils/avatar";

// Types
import { ContextType } from "../../@types/context";
import { UserInterface } from "../../@types/user";

type Props = {
  datas: UserInterface;
  imageUrl?: string;
};

function UserProfile({ datas, imageUrl }: Props) {
  // Libraries
  const navigate = useNavigate();

  // Contexts
  const { handleClick, isClicked } = useStateContext() as ContextType;

  // Hooks
  const logout = useLogout();

  // Events
  const signOut = () => {
    handleClick("userProfile", !isClicked.userProfile);

    logout();
  };

  // JSX
  return (
    <div className="nav-item absolute right-1 top-20 w-80 min-w-fit">
      <div className="grid grid-cols-1 divide-shadow-text w-full bg-white rounded shadow-lg">
        <div className="flex gap-5 items-center mt-6 px-5 w-full">
          <Avatar
            src={imageUrl}
            {...stringAvatar(`${datas?.firstName} ${datas?.lastName}`)}
            className="h-16 w-16"
          />
          <div className="flex flex-col">
            <h3 className="font-semibold">{`${datas?.firstName} ${datas?.lastName}`}</h3>
            <p className="text-hint text-gray-500 mt-1">{datas.companyName}</p>
            <p className="text-hint text-gray-500 font-semibold mt-[2px]">
              {datas.email}
            </p>
          </div>
        </div>
        <div className="mt-5 px-5 py-4">
          <Button onClick={signOut} className="btn btn-alert w-full">
            Logout
          </Button>
        </div>
      </div>
    </div>
  );
}

export default UserProfile;
