import { FileInterface } from "../@types/file";

export const getFileSize = (size: number) => {
  const totalBytes = size;

  let fileSize: string;

  if (totalBytes < 1000000) {
    fileSize = Math.floor(totalBytes / 1000) + " KB";
  } else {
    fileSize = Math.floor(totalBytes / 1000000) + " MB";
  }

  return fileSize;
};

export const getUrl = (obj?: Blob | MediaSource) => {
  if (!obj) return;

  const url = URL.createObjectURL(obj);

  return url;
};

export const getIdFromAttachments = (attachments: FileInterface[]) => {
  if (attachments.length === 0) return;

  let array: string[] = [];

  array = attachments.map(({ id }) => id);

  return array.join(", ");
};

export const getExtension = (filename: string) => {
  return filename.substring(filename.lastIndexOf(".") + 1);
};

export const formatFileName = (string: string) => {
  if (string.length <= 20) {
    return string.trim();
  }

  return string.slice(0, 20).trim() + "...";
};
