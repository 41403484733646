// Third Party Type Imports
import { useLocation, useNavigate } from "react-router-dom";

// Contexts
import { useAuthContext } from "../contexts/AuthContextProvider";

// Types
import { AuthContextType } from "../@types/authContext";

// Constants
import { ALERT } from "../@consts/alert";

const useLogout = () => {
  // Libraries
  const navigate = useNavigate();
  const location = useLocation();

  const { setAuth } = useAuthContext() as AuthContextType;

  const logout = (status: number = 200) => {
    localStorage.removeItem("userId");
    localStorage.removeItem("accessToken");
    setAuth(null);

    if (status === 200) {
      handlLogoutNavigation();
    }

    if (status === 401) {
      handleUnauthorized();
    }
  };

  const handlLogoutNavigation = () => {
    navigate("/login", {
      state: {
        showAlert: true,
        alertType: ALERT.success,
        alertBody: "Logout successfully",
      },
      replace: true,
    });
  };

  const handleUnauthorized = () => {
    navigate("/login", {
      state: {
        from: location,
        showAlert: true,
        alertType: ALERT.error,
        alertBody: "Unauthorized",
      },
      replace: true,
    });
  };

  return logout;
};

export default useLogout;
