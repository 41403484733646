// Custom Third Party Libraries
import { AxiosResponse } from "axios";
import { ChallengeInterface } from "../@types/challenge";
import { axiosPrivate } from "./axios";

export const getHomepageChallenges = async (): Promise<
  AxiosResponse<ChallengeInterface[]>
> => {
  return await axiosPrivate.get(`/home/challenges`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    },
  });
};
