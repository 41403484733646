// System
import { useEffect, useState } from "react";

// Third Parties
import Moment from "react-moment";

// Modules
import { Link } from "react-router-dom";

// Components
import { XAvatar } from "..";

// Utils
import { formatState, formatToCssStateClass } from "../../utils/state";

type Props = {
  id: string;
  title: string;
  userId: string;
  userName: string;
  createdAt: string;
  state: string;
};

const ChallengeSolutionRow = ({
  id,
  title,
  userId,
  userName,
  createdAt,
  state,
}: Props) => {
  // Data State
  const [stateStyle, setStateStyle] = useState("under-review");

  // Events
  const handleStateStyle = (state: string) => {
    setStateStyle(state);
  };

  useEffect(() => {
    handleStateStyle(formatToCssStateClass(state));
  }, [state]);
  return (
    <div
      key={id}
      className="flex flex-row items-center mt-[15px] h-fit px-[10px] py-[13px] bg-slate-bg rounded shadow heading-card-title text-black-text"
    >
      <div className="flex-initial basis-7/12 text-comment">
        <Link to={`/solutions/${id}`} className="no-underline text-black-text">
          {title}
        </Link>
      </div>
      <div className="flex flex-initial items-center basis-3/12">
        <XAvatar id={userId} name={userName} size={32} fontSize={14} />
        <div className="flex flex-col">
          <p className="text-hint">{userName}</p>
          <span className="micro-copy">
            <Moment format="MMMM DD, YYYY">{createdAt}</Moment>
          </span>
        </div>
      </div>
      <div className="flex-initial justify-end basis-2/12">
        <span className={`status text-xs submitted ${stateStyle}`}>
          {formatState(state)}
        </span>
      </div>
    </div>
  );
};

export default ChallengeSolutionRow;
