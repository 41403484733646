export const sortedOptions = (options: any[], labelKey: string) => {
  return options.sort((a, b) => {
    const labelA = a[labelKey].toUpperCase();
    const labelB = b[labelKey].toUpperCase();

    if (labelA < labelB) return -1;
    if (labelA > labelB) return 1;
    return 0;
  });
};
