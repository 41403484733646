// System
import { useEffect, useState } from "react";

// icons
import { HiInformationCircle } from "react-icons/hi";

// Modules
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack5";
import { Formik, FormikHelpers } from "formik";
import { useMutation } from "@tanstack/react-query";
import {
  AlertColor,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  createFilterOptions,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormHelperText,
  TextField,
} from "@mui/material";

// API
import { register } from "../../../api/authentification";

// Contexts
import { useSnackbarContext } from "../../../contexts/SnackbarContextProvider";

// Custom Hooks
import useSnackbar from "../../../hooks/useSnackbar";

// Components
import { XSnackbar, HtmlTooltip } from "../../../components";

// Assets
import manuvateAggreement from "./assests/pdf/agreement.pdf";

// Styles
import { outlinedButton } from "../../../@styles/outlinedButton";
import { containedButton } from "../../../@styles/containedButton";

// Utils
import { industriesToString } from "../../../utils/industry";
import { disciplinesToString } from "../../../utils/discipline";
import { sortedOptions } from "../../../utils/autocomplete";

// Schemas
import {
  RegisterInitialValue,
  RegisterForm,
  RegisterSchema,
} from "./consts/schemas";

// Types
import { ErrorInterface } from "../../../@types/response";
import { DisciplineOptionType } from "../../../@consts/discipline";
import { SnackbarContextType } from "../../../@types/snackbarContext";

// Constants
import { ALERT } from "../../../@consts/alert";
import { countryOptions, CountryOptionType } from "../../../@consts/country";
import { defaultSuccess } from "../../../@consts/response";
import { disciplineOptions } from "../../../@consts/discipline";
import { industryOptions } from "../../../@consts/industry";
import { ROLES } from "../../../@consts/roles";

const disciplineFilter = createFilterOptions<DisciplineOptionType>({
  matchFrom: "start",
  stringify: (option) => option.title,
});
const countryFilter = createFilterOptions<CountryOptionType>({
  matchFrom: "start",
  stringify: (option) => option.label,
});

type LocationState = {
  email?: string;
  role?: string;
};

const countries = sortedOptions(countryOptions, "label");
const disciplines = sortedOptions(disciplineOptions, "title");

const Register = () => {
  // Libraries
  const navigate = useNavigate();
  const location = useLocation();

  // Constants
  const invitedEmail = (location.state as LocationState)?.email;
  const invitedRole = (location.state as LocationState)?.role;

  // Contexts
  const { openSnackbar, setOpenSnackbar, alertType, alertBody } =
    useSnackbarContext() as SnackbarContextType;

  // Hooks
  const snackbar = useSnackbar();

  // Data State
  const [initialEmail, setInitialEmail] = useState<string>("");
  const [initialRole, setInitialRole] = useState<string>(ROLES.SEEKER);

  // Components State
  const [openTAC, setOpenTAC] = useState(false);
  const [numPages, setNumPages] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState(1);

  // Events
  const handleFailure = (alertBody: string) => {
    snackbar(ALERT.error, alertBody);
  };

  const handleValidation = (formIsValid: boolean) => {
    if (!formIsValid) {
      snackbar(ALERT.error, "Validation error");
    }
  };

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
    setPageNumber(1);
  };

  const changePage = (offset: number) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  const previousPage = () => {
    changePage(-1);
  };

  const nextPage = () => {
    changePage(1);
  };

  const handleInvitedUser = (email: string, role: string) => {
    setInitialEmail(email);
    setInitialRole(role);
  };

  // Form Submit
  const onSubmit = (
    values: RegisterForm,
    actions: FormikHelpers<RegisterForm>
  ) => {
    registerMutation.mutate(values);

    actions.setSubmitting(false);
  };

  // Mutation
  const registerMutation = useMutation(register, {
    onSuccess: (data, variables: RegisterForm) => {
      const alertBody = data.data.message ?? defaultSuccess;

      navigate("/login", {
        state: { email: variables.email, newUser: true, alertBody },
        replace: true,
      });
    },
    onError: (error: ErrorInterface) => {
      const alertBody = error.response.data?.message || error.message;

      handleFailure(alertBody);
    },
  });

  // Effects
  useEffect(() => {
    if (invitedEmail && invitedRole) {
      handleInvitedUser(invitedEmail, invitedRole);
    }
  }, []);

  // JSX
  return (
    <>
      <h1>Register now</h1>
      <p className="mt-2 heading-card-title">
        Register an account today to have your industry challenge solved and to
        solve other people challenges.
      </p>
      <p className="mt-4 heading-card-title">
        Already have an account?{" "}
        <Link to={"/login"} className="no-underline text-blue-mid">
          Sign in
        </Link>
      </p>

      <div className="mt-8">
        <Formik
          enableReinitialize={true}
          initialValues={
            {
              ...RegisterInitialValue,
              email: initialEmail,
              role: initialRole,
            } as RegisterForm
          }
          validationSchema={RegisterSchema}
          onSubmit={onSubmit}
          validateOnMount={true}
        >
          {(props) => (
            <form
              onSubmit={props.handleSubmit}
              autoComplete="off"
              className="flex flex-col"
            >
              <label htmlFor="userName" className="text">
                Username<span className="text-orange-alt">*</span>
              </label>
              <TextField
                value={props.values.userName}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                error={
                  props.errors.userName && props.touched.userName ? true : false
                }
                helperText={
                  props.errors.userName && props.touched.userName
                    ? props.errors.userName
                    : ""
                }
                variant="standard"
                type="text"
                name="userName"
                id="userName"
                placeholder="Enter unique username"
                className="mt-4"
              />
              <div className="flex flex-row justify-between gap-4 mt-4">
                <div className="flex flex-col w-full">
                  <label htmlFor="firstName" className="text">
                    First name<span className="text-orange-alt">*</span>
                  </label>
                  <TextField
                    value={props.values.firstName}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    error={
                      props.errors.firstName && props.touched.firstName
                        ? true
                        : false
                    }
                    helperText={
                      props.errors.firstName && props.touched.firstName
                        ? props.errors.firstName
                        : ""
                    }
                    variant="standard"
                    type="text"
                    name="firstName"
                    id="firstName"
                    placeholder="Your first name"
                    className="mt-4"
                  />
                </div>
                <div className="flex flex-col w-full">
                  <label htmlFor="lastName" className="text">
                    Last name<span className="text-orange-alt">*</span>
                  </label>
                  <TextField
                    value={props.values.lastName}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    error={
                      props.errors.lastName && props.touched.lastName
                        ? true
                        : false
                    }
                    helperText={
                      props.errors.lastName && props.touched.lastName
                        ? props.errors.lastName
                        : ""
                    }
                    variant="standard"
                    type="text"
                    name="lastName"
                    id="lastName"
                    placeholder="Last name"
                    className="mt-4"
                  />
                </div>
              </div>
              <label htmlFor="email" className="mt-4 text">
                Email Address<span className="text-orange-alt">*</span>
              </label>
              <TextField
                disabled={initialEmail !== "" ? true : false}
                value={props.values.email}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                error={props.errors.email && props.touched.email ? true : false}
                helperText={
                  props.errors.email && props.touched.email
                    ? props.errors.email
                    : ""
                }
                variant="standard"
                type="email"
                name="email"
                id="email"
                placeholder="Enter valid email"
                className="mt-4"
              />
              <label htmlFor="password" className="mt-4 text">
                Password<span className="text-orange-alt">*</span>
              </label>
              <TextField
                value={props.values.password}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                error={
                  props.errors.password && props.touched.password ? true : false
                }
                helperText={
                  props.errors.password && props.touched.password
                    ? props.errors.password
                    : ""
                }
                variant="standard"
                type="password"
                name="password"
                id="password"
                placeholder="Enter strong password"
                className="mt-4"
              />
              <label htmlFor="confirmPassword" className="mt-4 text">
                Re-enter password<span className="text-orange-alt">*</span>
              </label>
              <TextField
                value={props.values.confirmPassword}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                error={
                  props.errors.confirmPassword && props.touched.confirmPassword
                    ? true
                    : false
                }
                helperText={
                  props.errors.confirmPassword && props.touched.confirmPassword
                    ? props.errors.confirmPassword
                    : ""
                }
                variant="standard"
                type="password"
                name="confirmPassword"
                id="confirmPassword"
                placeholder="Enter password once again"
                className="mt-4"
              />
              <label htmlFor="assessorId" className="mt-4 text">
                SIRI Assessor ID
              </label>
              <TextField
                value={props.values.assessorId}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                error={
                  props.errors.assessorId && props.touched.assessorId
                    ? true
                    : false
                }
                helperText={
                  props.errors.assessorId && props.touched.assessorId
                    ? props.errors.assessorId
                    : ""
                }
                variant="standard"
                type="text"
                name="assessorId"
                id="assessorId"
                placeholder="Enter your SIRI Assessor ID"
                className="mt-4"
              />
              <label htmlFor="companyName" className="mt-4 text">
                Company name<span className="text-orange-alt">*</span>
              </label>
              <TextField
                value={props.values.companyName}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                error={
                  props.errors.companyName && props.touched.companyName
                    ? true
                    : false
                }
                helperText={
                  props.errors.companyName && props.touched.companyName
                    ? props.errors.companyName
                    : ""
                }
                variant="standard"
                type="text"
                name="companyName"
                id="companyName"
                placeholder="Enter your company name"
                className="mt-4"
              />
              <label htmlFor="country" className="mt-4 text">
                Country<span className="text-orange-alt">*</span>
              </label>
              <p className="text-hint">Select your country or region</p>
              <Autocomplete
                onChange={(e, option) => {
                  props.setFieldValue("country", option?.label!);
                }}
                onBlur={props.handleBlur}
                id="country"
                options={countries}
                autoHighlight
                getOptionLabel={(option) => option.label}
                filterOptions={countryFilter}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    <img
                      loading="lazy"
                      width="20"
                      src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                      srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                      alt=""
                    />
                    {option.label}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField {...params} variant="standard" />
                )}
                className="mt-4"
              />
              {props.errors.country && props.touched.country && (
                <FormHelperText error>{props.errors.country}</FormHelperText>
              )}
              <label htmlFor="industryGroupSector" className="mt-4 text">
                Industry group<span className="text-orange-alt">*</span>
              </label>
              <p className="text-hint">
                Select the industry 4.0 categories that best represents your
                challenge
              </p>
              <Autocomplete
                onChange={(e, arrays) => {
                  props.setFieldValue(
                    "industryGroupSector",
                    industriesToString(arrays)
                  );
                }}
                onBlur={props.handleBlur}
                multiple
                id="industryGroupSector"
                options={industryOptions}
                getOptionLabel={(option) => option.title}
                renderInput={(params) => (
                  <TextField {...params} variant="standard" />
                )}
                className="mt-4"
              />
              {props.errors.industryGroupSector &&
                props.touched.industryGroupSector && (
                  <FormHelperText error>
                    {props.errors.industryGroupSector}
                  </FormHelperText>
                )}
              <label htmlFor="disciplines" className="mt-4 text flex gap-x-1">
                <span>
                  Disciplines<span className="text-orange-alt">*</span>
                </span>
                <span className="micro-copy text-[12px] flex flex-row items-center gap-1">
                  <HtmlTooltip
                    placement="right"
                    title={
                      <>
                        <p>
                          <b>If discipline exist</b> : Select the existing
                          disciplines
                        </p>
                        <p>
                          <b>If discipline not exist</b> : Add other disciplines
                          as well
                        </p>
                      </>
                    }
                  >
                    <HiInformationCircle className="text-lg cursor-pointer" />
                  </HtmlTooltip>
                </span>
              </label>
              <p className="text-hint">
                Select the manufacturing sector that best represents your
                business/domain
              </p>
              <Autocomplete
                id="disciplines"
                multiple
                onBlur={props.handleBlur}
                onChange={(event, arrays) => {
                  props.setFieldValue(
                    "disciplines",
                    disciplinesToString(arrays)
                  );
                }}
                filterOptions={(options, params) => {
                  const filtered = disciplineFilter(options, params);

                  const { inputValue } = params;
                  // Suggest the creation of a new value
                  const isExisting = options.some(
                    (option) => inputValue === option.title
                  );
                  if (inputValue !== "" && !isExisting) {
                    filtered.push({
                      inputValue,
                      title: `Add "${inputValue}"`,
                    });
                  }

                  return filtered;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                options={disciplines}
                getOptionLabel={(option) => {
                  // Value selected with enter, right from the input
                  if (typeof option === "string") {
                    return option;
                  }
                  // Add "xxx" option created dynamically
                  if (option.inputValue) {
                    return option.inputValue;
                  }
                  // Regular option
                  return option.title;
                }}
                renderOption={(props, option) => (
                  <li {...props}>{option.title}</li>
                )}
                renderInput={(params) => (
                  <TextField {...params} variant="standard" />
                )}
                className="mt-4"
              />
              {props.errors.disciplines && props.touched.disciplines && (
                <FormHelperText error>
                  {props.errors.disciplines}
                </FormHelperText>
              )}
              {/* <div className="inline-flex items-center mt-8">
                <p className="text-hint">
                  Please read and accept our{" "}
                  <span
                    // onClick={() => {
                    //   props.setFieldValue("checked", true);
                    //   setOpenTAC(!openTAC);
                    // }}
                    className="no-underline text-blue-mid"
                  >
                    terms and conditions
                  </span>{" "}
                  before submitting
                </p>
              </div> */}
              <Dialog
                open={openTAC}
                onClose={() => setOpenTAC(!openTAC)}
                fullWidth={true}
                maxWidth={"md"}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  <h2>Terms and Conditions</h2>
                </DialogTitle>
                <DialogContent dividers={true}>
                  <DialogContentText
                    textAlign="justify"
                    id="alert-dialog-description"
                  >
                    <Document
                      file={manuvateAggreement}
                      onLoadSuccess={onDocumentLoadSuccess}
                    >
                      <Page width={800} pageNumber={pageNumber} />
                    </Document>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <p>
                        Page {pageNumber || (numPages ? 1 : "--")} of{" "}
                        {numPages || "--"}
                      </p>
                      <div>
                        <button
                          type="button"
                          disabled={pageNumber <= 1}
                          onClick={previousPage}
                        >
                          Previous
                        </button>
                        <button
                          style={{ marginLeft: 10 }}
                          type="button"
                          disabled={numPages !== null && pageNumber >= numPages}
                          onClick={nextPage}
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      props.setFieldValue("checked", false);
                      setOpenTAC(!openTAC);
                    }}
                    sx={outlinedButton}
                  >
                    Disagree
                  </Button>
                  <Button
                    variant="contained"
                    disabled={props.isSubmitting}
                    type="submit"
                    onClick={() => {
                      handleValidation(props.isValid);

                      props.submitForm();

                      setOpenTAC(!openTAC);
                    }}
                    autoFocus
                    sx={containedButton}
                  >
                    Agree & Submit
                  </Button>
                </DialogActions>
              </Dialog>
              <div className="flex flex-row justify-between mt-4 mb-12">
                <div>
                  <Button
                    onClick={() => {
                      props.setFieldValue("checked", true);

                      setOpenTAC(!openTAC);
                    }}
                    className="btn btn-primary disabled:text-white disabled:opacity-75"
                  >
                    Register now
                  </Button>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>

      {openSnackbar && (
        <XSnackbar
          open={openSnackbar}
          onClose={() => {
            setOpenSnackbar(false);
          }}
          alertType={alertType as AlertColor}
          alertBody={alertBody}
        />
      )}
    </>
  );
};

export default Register;
