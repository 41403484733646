// Third Party Types
import { Alert, AlertColor, Snackbar } from "@mui/material";

type Props = {
  open: boolean;
  onClose: () => void;
  alertType: AlertColor;
  alertBody: string;
};

const XSnackbar = ({ open, onClose, alertType, alertBody }: Props) => {
  return (
    <Snackbar
      open
      autoHideDuration={6000}
      onClose={onClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
    >
      <Alert severity={alertType}>{alertBody}</Alert>
    </Snackbar>
  );
};

export default XSnackbar;
