// System
import { useState } from "react";

// Third Party Type Imports
import { Pagination } from "@mui/material";
import { useQuery } from "@tanstack/react-query";

// API
import { getEvaluatorChallenges } from "../../../api/challenge";

// Custom Hooks
import useLogout from "../../../hooks/useLogout";

// Components
import { ChallengeCard, ContentHeader, Spinner } from "../../../components";

// Types
import { ChallengeInterface } from "../../../@types/challenge";
import { ErrorInterface } from "../../../@types/response";

const EvaluatorChallenges = () => {
  // Hooks
  const logout = useLogout();

  // Components State
  const [count, setCount] = useState(0);
  const [perPage, setPerPage] = useState(12);
  const [currentPage, setCurrentPage] = useState(1);

  const begin = (currentPage - 1) * perPage;
  const end = begin + perPage;

  // Data Fetching
  const challenges = useQuery(["challenges"], () => getEvaluatorChallenges(), {
    onSuccess(data) {
      setCount(Math.ceil(data.data.length / perPage));
    },
    onError(err: ErrorInterface) {
      if (err.response.status === 401) {
        const unauthorized = err.response.status;

        logout(unauthorized);
      }
    },
  });

  return (
    <>
      <div
        id="header"
        className="flex flex-col md:flex-row md:justify-between gap-y-4"
      >
        <div className="flex flex-col">
          <ContentHeader h1="Evaluator challenges" />
        </div>
      </div>
      {challenges.isLoading ? (
        <Spinner />
      ) : challenges.data?.data!.length < 1 ? (
        <div className="flex flex-col w-full justify-center items-center mt-10">
          <p className="text text-gray-500 mt-4">
            No pending challenges submitted
          </p>
        </div>
      ) : (
        <>
          <div className="mt-5 grid grid-cols-1 sm:grid-cols-2 xl:sm:grid-cols-4 gap-5">
            {challenges.data
              ?.data!.slice(begin, end)
              .map((challenge: ChallengeInterface) => (
                <ChallengeCard
                  key={challenge.id}
                  id={challenge.id}
                  imageId={challenge.imageId}
                  title={challenge.title}
                  state={challenge.state}
                  awardAmount={challenge.awardAmount}
                  userId={challenge.userId}
                  userName={challenge.userName}
                  endDate={challenge.endDate}
                />
              ))}
          </div>

          {count > 1 && (
            <div className="mt-5 flex justify-start">
              <Pagination
                shape="rounded"
                count={count}
                onChange={(e, p) => {
                  setCurrentPage(p);
                }}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default EvaluatorChallenges;
