// Custom Third Party Libraries
import { axiosPrivate } from "./axios";

// Types
type AttachmentForm = {
  objectId: string;
  objectType: "CHALLENGE" | "SOLUTION" | "COMMENT";
  fileId: string;
};

// Constants
const baseURL = process.env.REACT_APP_BACKEND_SERVICE_URI;

export const createNewFile = async (values: FormData) => {
  return await axiosPrivate.post(`/common/files/upload`, values, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    },
  });
};

export const createNewAttachment = async (values: AttachmentForm) => {
  return await axiosPrivate.post(`/common/attachments`, values, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    },
  });
};

export const getAttachments = async (objectId: string) => {
  return await axiosPrivate.get(`/common/attachments?objectId=${objectId}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    },
  });
};

export const getFile = async (fileId: string) => {
  const accessToken = localStorage.getItem("accessToken") || "null";

  const result = await fetch(`${baseURL}common/files/${fileId}`, {
    credentials: "include",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  const blob = await result.blob();

  return blob;
};

export const removeAttachment = async (attachmentId: string) => {
  const result = await axiosPrivate.delete(
    `/common/attachments/${attachmentId}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    }
  );

  return result;
};
