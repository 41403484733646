// Modules
import { AxiosResponse } from "axios";
import { SolutionFeedbackInterface } from "../@types/solutionFeedback";
import { FeedbackForm } from "../pages/Solutions/Solution/consts/schemas";
import { axiosPrivate } from "./axios";

export const createNewFeedback = async (values: FeedbackForm) => {
  return await axiosPrivate.post("/solution-feedback", values, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    },
  });
};

export const getSolutionFeedback = async (
  id: string
): Promise<AxiosResponse<SolutionFeedbackInterface>> => {
  return await axiosPrivate.get(`/solutions/${id}/feedback`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    },
  });
};
