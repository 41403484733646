// Modules
import { Outlet } from "react-router-dom";

// Contexts
import { useStateContext } from "../../contexts/ContextProvider";

// Components
import { XScrollToTop, Footer, Navbar, Sidebar } from "../../components";

// Styles
import "./MainLayout.css";

// Types
import { ContextType } from "../../@types/context";

const MainLayout = () => {
  // Contexts
  const { activeMenu } = useStateContext() as ContextType;

  // JSX
  return (
    <>
      <div className="flex relative">
        {activeMenu ? (
          <div className="w-[317px] fixed sidebar bg-blue-deep">
            <Sidebar />
          </div>
        ) : (
          <div className="w-0">
            <Sidebar />
          </div>
        )}
        <div
          className={`bg-slate-bg flex flex-col min-h-screen w-full ${
            activeMenu ? "md:ml-[317px]" : "flex-2"
          }`}
        >
          <div className="fixed md:static navbar w-full bg-white shadow-md">
            <Navbar />
          </div>

          <div className="p-8 pt-24 md:pt-8">
            <Outlet />
          </div>

          <div className="mt-auto px-8 pb-8">
            <Footer />
          </div>
        </div>
      </div>

      <XScrollToTop />
    </>
  );
};

export default MainLayout;
