// Third Party Type Imports
import { useQuery } from "@tanstack/react-query";
import { AiOutlineLink } from "react-icons/ai";

// API
import { getFile } from "../../api/file";

// Utils
import { getExtension, getFileSize, getUrl } from "../../utils/file";

// Types
type Props = {
  id: string;
  name: string;
};

const AttachmentInline = ({ id, name }: Props) => {
  const file = useQuery(["file", id], () => getFile(id));

  // JSX
  return (
    <a
      href={getUrl(file.data)}
      target="_blank"
      rel="noopener noreferrer"
      className="flex flex-row items-center text-blue-mid hover:text-blue-deep cursor-pointer text-hint no-underline"
    >
      <AiOutlineLink className="mr-1" />
      <p>{name}</p>
    </a>
  );
};

export default AttachmentInline;
