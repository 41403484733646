// System
import { useRef } from "react";

// Third Parties
import SunEditor from "suneditor-react";
import SunEditorCore from "suneditor/src/lib/core";

// Styles
import "suneditor/dist/css/suneditor.min.css";
import "./Editor.css";

// Types
type Props = {
  onChange: (context: string) => void;
  defaultValue?: string;
  setContents?: string;
};

const Editor = ({ onChange, defaultValue, setContents }: Props) => {
  const editor = useRef<SunEditorCore>();

  // The sunEditor parameter will be set to the core suneditor instance when this function is called
  const getSunEditorInstance = (sunEditor: SunEditorCore) => {
    editor.current = sunEditor;
  };

  return (
    <>
      <SunEditor
        onChange={onChange}
        defaultValue={defaultValue}
        setContents={setContents}
        getSunEditorInstance={getSunEditorInstance}
        lang="en"
        height="100%"
        setDefaultStyle="font-family: Open Sans; font-size: 16px; line-height: 23px;"
        setOptions={{
          buttonList: [
            ["undo", "redo"],
            [/* ,'font', */ "formatBlock", "fontSize"],
            ["paragraphStyle", "blockquote"],
            [
              "bold",
              "underline",
              "italic",
              "strike",
              "subscript",
              "superscript",
            ],
            ["fontColor", "hiliteColor"],
            ["removeFormat"],
            ["outdent", "indent"],
            ["align", "horizontalRule", "list", "lineHeight"],
            ["table", "link"],
            ["fullScreen", "print"],
          ],
        }}
      />
    </>
  );
};
export default Editor;
