import * as yup from "yup";
import { solutionStates } from "../../../../@consts/state";

export const SolutionInitialValue = {
  title: "",
  details: "",
  state: solutionStates.draft,
};

export interface SolutionForm {
  id?: string;
  title: string;
  details: string;
  state: string;
  challengeId?: string;
}

export const SolutionSchema = yup.object().shape({
  title: yup.string().required("Required"),
  details: yup.string().required("Required"),
  state: yup
    .string()
    .oneOf(
      [solutionStates.draft, solutionStates.submitted],
      "Invalid State Type"
    )
    .required("Required"),
});
