// Third Party Type Imports
import { useDropzone, Accept, FileRejection } from "react-dropzone";

// Types
type Props = {
  children: JSX.Element;
  multiple?: boolean;
  maxSize?: number;
  maxFiles?: number;
  accept?: Accept;
  onDrop: (acceptedFiles: File[]) => void;
  onDropRejected?: (fileRejections: FileRejection[]) => void;
};

const Dropzone = ({
  children,
  multiple,
  maxSize,
  maxFiles,
  accept,
  onDrop,
  onDropRejected,
}: Props) => {
  const { getRootProps, getInputProps } = useDropzone({
    multiple,
    maxSize,
    maxFiles,
    accept,
    onDrop,
    onDropRejected,
  });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {children}
    </div>
  );
};

export default Dropzone;
