import * as yup from "yup";

export const ProfileInitialValue = {
  userName: "",
  firstName: "",
  lastName: "",
  email: "",
  companyName: "",
  country: "",
  industryGroupSector: "",
  disciplines: "",
};

export interface ProfileForm {
  id?: string;
  userName?: string;
  firstName: string;
  lastName: string;
  email?: string;
  role?: string;
  companyName: string;
  country: string;
  industryGroupSector: string;
  disciplines: string;
  assessorId?: string;
}

export const ProfileSchema = yup.object().shape({
  userName: yup
    .string()
    .min(3, "Username must be at least 3 characters long")
    .required("Required"),
  firstName: yup.string().required("Required"),
  lastName: yup.string().required("Required"),
  email: yup.string().email("Please enter a valid email").required("Required"),
  companyName: yup.string().required("Required"),
  country: yup.string().required("Required"),
  industryGroupSector: yup.string().required("Required"),
  disciplines: yup.string().required("Required"),
  checked: yup.boolean().oneOf([true], "Accept the terms and conditions"),
  assessorId: yup.string().nullable(),
});
