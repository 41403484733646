// Libraries
import { useNavigate, useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

// API
import { verify } from "../../../api/authentification";

// Components
import { Spinner } from "../../../components";
import { ErrorInterface } from "../../../@types/response";

// Constants
import { ALERT } from "../../../@consts/alert";

const VerifyUser = () => {
  // Libraries
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // Constants
  const token = searchParams.get("token");
  const email = searchParams.get("email");
  const encodeEmail = encodeURIComponent(email ?? "");

  // Data Fetching
  const { isLoading, isError, isSuccess } = useQuery(
    ["verify"],
    () => verify({ email: encodeEmail, token }),
    {
      onSuccess(data) {
        navigate("/login", {
          state: {
            email: email,
            newUser: true,
            alertBody: "Account verified successfuly",
          },
          replace: true,
        });
      },
      onError(err: ErrorInterface) {
        navigate("/login", {
          state: {
            showAlert: true,
            alertType: ALERT.error,
            alertBody: "Failed to verify",
          },
          replace: true,
        });
      },
    }
  );

  return <Spinner full />;
};

export default VerifyUser;
