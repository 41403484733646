// System
import React, { createContext, useContext, useState } from "react";

// Types
import { ContextType } from "../@types/context";

type Props = {
  children: React.ReactNode;
};

// Constants
const StateContext = createContext<ContextType | null>(null);

const initialState = {
  userProfile: false,
  notification: false,
};

export const ContextProvider = ({ children }: Props) => {
  const [activeMenu, setActiveMenu] = useState(true);
  const [screenSize, setScreenSize] = useState(undefined);
  const [isClicked, setIsClicked] = useState(initialState);

  const handleClick = (state: string, status: boolean) =>
    setIsClicked({ ...initialState, [state]: status });

  return (
    <StateContext.Provider
      value={{
        activeMenu,
        setActiveMenu,
        screenSize,
        setScreenSize,
        initialState,
        handleClick,
        isClicked,
        setIsClicked,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};
export const useStateContext = () => useContext(StateContext);
