import { countryOptions, CountryOptionType } from "../@consts/country";

export const countryToArray = (industriesString: string): CountryOptionType => {
  const index = countryOptions.map((e) => e.label).indexOf(industriesString);

  return countryOptions[index];
};

export const formatCountry = (name?: string) => {
  if (!name) return "";

  let arr = name.split("");
  let result = "";

  for (let i = 0; i < arr.length; i++) {
    if (arr[i] === " ") {
      arr[i] = "-";
    }
  }

  result = arr.join("").toLowerCase();

  return result;
};

export const deformatCountry = (formatName: string) => {
  let arr = formatName.split("");
  let result = "";

  for (let i = 0; i < arr.length; i++) {
    if (arr[i] === "-") {
      arr[i] = " ";
    }
  }

  result = toCapitalize(arr.join(""));

  return result;
};

const toCapitalize = (string: string) => {
  const arr = string.split(" ");

  for (let i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }

  return arr.join(" ");
};
