export const containedButton = {
  paddingY: "8px",
  paddingX: "24px",
  borderRadius: "4px",
  textTransform: "none",
  fontWeight: 500,
  fontSize: "15px",
  lineHeight: "20px",
  bgcolor: "#39BAE9",
  "&:hover": {
    bgcolor: "#292A61",
  },
};
