import { ScoreInterface } from "../../@types/evaluatorScore";
import EvaluatorScoreList from "../EvaluatorScoreList/EvaluatorScoreList";
import OwnerChat from "../OwnerChat/OwnerChat";

type Props = {
  clickedSection: string;
  setClickedSection: (clickedSection: "score" | "chat") => void;
  scoreList: ScoreInterface[];
  solutionId: string;
  evaluatorId: string | null;
};

const ScoreListAndChat = ({
  clickedSection,
  setClickedSection,
  scoreList,
  solutionId,
  evaluatorId,
}: Props) => {
  return (
    <div className="flex w-1/2 flex-col gap-y-5">
      <div className="flex flex-row gap-x-5">
        <div
          className={`w-1/2 cursor-pointer pb-2 ${
            clickedSection === "score" ? "border-b-2 border-blue-deep" : null
          } `}
          onClick={() => setClickedSection("score")}
        >
          Score List
        </div>
        <div
          className={`w-1/2 cursor-pointer pb-2 ${
            clickedSection === "chat" ? "border-b-2 border-blue-deep" : null
          } `}
          onClick={() => setClickedSection("chat")}
        >
          Private Chat
        </div>
      </div>
      {clickedSection === "score" ? (
        <>
          {scoreList.map(({ score, label, percentage }) => (
            <EvaluatorScoreList
              score={score}
              label={label}
              percentage={percentage}
            />
          ))}
        </>
      ) : (
        <OwnerChat solutionId={solutionId} evaluatorId={evaluatorId} />
      )}
    </div>
  );
};

export default ScoreListAndChat;
