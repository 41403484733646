export const challengeStates = {
  draft: "DRAFT",
  pendingApproval: "PENDING_APPROVAL",
  published: "PUBLISHED",
  closed: "CLOSED",
};

export const solutionStates = {
  draft: "DRAFT",
  submitted: "SUBMITTED",
  underReview: "UNDER_REVIEW",
  underEvaluation: "UNDER_EVALUATION",
  rejected: "REJECTED",
  awarded: "AWARDED",
};
