import * as yup from "yup";

export const EvaluatorCriteriaScoreObjectSchema = yup.object({
  challengeCriteriaId: yup.string().required(),
  score: yup.number().integer().min(0).max(5).required(),
});

export const EvaluatorCriteriaScoreSchema = yup.object().shape({
  id: yup.string().required(),
  data: yup.array().of(EvaluatorCriteriaScoreObjectSchema).required(),
});

export interface EvaluatorCriteriaScoreForm
  extends yup.InferType<typeof EvaluatorCriteriaScoreSchema> {}

export const EvaluatorCriteriaInitialForm = {} as EvaluatorCriteriaScoreForm;
