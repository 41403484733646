// System
import { useRef } from "react";

// Third Parties
import SunEditor from "suneditor-react";
import SunEditorCore from "suneditor/src/lib/core";

// Styles
import "suneditor/dist/css/suneditor.min.css";
import "./CommentEditor.css";

// Types
type Props = {
  onChange: (context: string) => void;
  defaultValue?: string;
  setContents?: string;
};

const CommentEditor = ({ onChange, defaultValue, setContents }: Props) => {
  const editor = useRef<SunEditorCore>();

  const getSunEditorInstance = (sunEditor: SunEditorCore) => {
    editor.current = sunEditor;
  };

  return (
    <>
      <SunEditor
        onChange={onChange}
        defaultValue={defaultValue}
        setContents={setContents}
        getSunEditorInstance={getSunEditorInstance}
        lang="en"
        height="100%"
        // setDefaultStyle="height: 100px; max-height: fit-content; overscroll-behavior: none;"
        setDefaultStyle="font-family: Open Sans; font-size: 16px; line-height: 23px;"
        placeholder="Type your message here..."
        autoFocus
        setOptions={{
          buttonList: [["link"]],
        }}
      />
    </>
  );
};
export default CommentEditor;
