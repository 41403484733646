// System
import { useEffect, useState } from "react";

// Third Parties
import Moment from "react-moment";

// Modules
import { Link } from "react-router-dom";
import { BiLinkExternal } from "react-icons/bi";

// Components
import { XAvatar } from "..";

// Utils
import { formatState, formatToCssStateClass } from "../../utils/state";

// Constants
import { solutionStates } from "../../@consts/state";

type Props = {
  id: string;
  title: string;
  userId: string;
  challengeId: string;
  createdAt: string;
  state: string;
};

const UserSolutionRow = ({
  id,
  title,
  challengeId,
  createdAt,
  state,
}: Props) => {
  // Data State
  const [stateStyle, setStateStyle] = useState("under-review");

  // Events
  const handleStateStyle = (state: string) => {
    setStateStyle(state);
  };

  const getRedirectLink = (id: string, state: string) => {
    let result: string = "";

    switch (state) {
      case solutionStates.draft:
        result = `/solutions/${id}/edit`;
        break;

      default:
        result = `/solutions/${id}`;
        break;
    }

    return result;
  };

  useEffect(() => {
    handleStateStyle(formatToCssStateClass(state));
  }, [state]);

  return (
    <div
      key={id}
      className="flex flex-row mt-[15px] h-fit p-[17px] bg-white rounded shadow heading-card-title text-black-text"
    >
      <div className="flex-initial basis-8/12">
        <Link
          to={getRedirectLink(id, state)}
          state={{ challengeId: challengeId }}
          className="no-underline text-black-text"
        >
          {title}
        </Link>
      </div>
      <div className="flex-initial basis-2/12">
        <Moment format="MMMM DD, YYYY">{createdAt}</Moment>
      </div>
      <div className="flex-initial basis-1/12">
        <span className={`status text-xs submitted ${stateStyle}`}>
          {formatState(state)}
        </span>
      </div>
      <div className="flex-none basis-1/12 text-right items-center">
        <Link to={`/challenges/${challengeId}`} className="no-underline">
          <BiLinkExternal />
        </Link>
      </div>
    </div>
  );
};

export default UserSolutionRow;
