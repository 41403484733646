// System
import { useEffect } from "react";

// Libraries
import { useNavigate, useSearchParams } from "react-router-dom";

// Components
import { Spinner } from "../../../components";

// Constants
import { ROLES } from "../../../@consts/roles";

const InvitedEvaluator = () => {
  // Libraries
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // Constants
  const evaluatorEmail = searchParams.get("email");
  const encodeEmail = encodeURIComponent(evaluatorEmail ?? "");

  // Events
  const handleInvitedEvaluator = (email: string) => {
    navigate("/register", {
      state: {
        email,
        role: ROLES.EVALUATOR,
      },
      replace: true,
    });
  };

  // Effects
  useEffect(() => {
    !evaluatorEmail
      ? navigate("/login", {
          replace: true,
        })
      : handleInvitedEvaluator(encodeEmail);
  }, []);

  return <Spinner full />;
};

export default InvitedEvaluator;
