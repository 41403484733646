import * as yup from "yup";
import { solutionStates } from "../../../../@consts/state";

export const CommentInitialValue = {
  comment: "",
  isPrivate: false,
};

export const FeedbackInitialValue = {
  solutionState: "",
  userComment: "",
  awardAmount: 0,
  solutionId: "",
};

export const ReviewInitialValue = {
  rating: 0,
  review: "",
  userId: "",
  reviewerId: "",
  reviewerType: "",
  reviewedObjectId: "",
  reviewedObjectType: "",
};

export interface CommentForm {
  comment: string;
  solutionId?: string;
  hasFile?: boolean;
  isPrivate?: boolean;
}

export interface FeedbackForm {
  solutionState: string;
  userComment: string;
  awardAmount?: number;
  solutionId: string;
}

export interface ReviewForm {
  rating: number;
  review: string;
  userId: string;
  reviewerId: string;
  reviewerType: string;
  reviewedObjectId: string;
  reviewedObjectType: string;
}

export const CommentSchema = yup.object().shape({
  comment: yup.string().required("Required"),
  isPrivate: yup.boolean().required("Required"),
});

export const FeedbackSchema = yup.object().shape({
  solutionState: yup
    .string()
    .oneOf(
      [
        solutionStates.underEvaluation,
        solutionStates.awarded,
        solutionStates.rejected,
      ],
      "Invalid State Type"
    )
    .required("Required"),
  userComment: yup.string().required("Required"),
  awardAmount: yup.number().when("solutionState", {
    is: solutionStates.awarded,
    then: (schema) =>
      schema
        .positive("Award amount must be positive number amount")
        .integer()
        .required("Required"),
  }),
  solutionId: yup.string().required("Required"),
});

export const ReviewSchema = yup.object().shape({
  rating: yup
    .number()
    .positive("rating must be positive number")
    .min(1)
    .integer()
    .required("Required"),
  review: yup.string().required("Required"),
  userId: yup.string().required("Required"),
  reviewerId: yup.string().required("Required"),
  reviewerType: yup.string().required("Required"),
  reviewedObjectId: yup.string().required("Required"),
  reviewedObjectType: yup.string().required("Required"),
});
