// Modules
import { Link } from "react-router-dom";

// Assets
import manuVateLogoWhite from "./assets/images/manuvate-logo-white.png";

const HomeNavbar = () => {
  return (
    <div className="w-full bg-blue-deep h-36 flex flex-row gap-6 items-center justify-between p-10">
      <div className="flex flex-col gap-2">
        <img
          src={manuVateLogoWhite}
          alt="ManuVate"
          className="w-52 h-10 text-white"
        />
        <div className="ml-2 flex flex-col gap-2">
          <p className="text-hint text-white">
            Brainstorm Ideas. Solve Challenges.
          </p>
          <p className="text-hint text-white">Drive Change and Innovate.</p>
        </div>
      </div>
      <div className="flex flex-col md:flex-row gap-2 lg:gap-10 justify-end lg:mr-10">
        <Link
          to={"/login"}
          className="no-underline text-xl font-semibold text-white"
        >
          Login
        </Link>
        <Link
          to={"/register"}
          className="no-underline text-xl font-semibold text-white"
        >
          Register
        </Link>
      </div>
    </div>
  );
};

export default HomeNavbar;
