import { EvaluatorOptionType } from "../@types/evaluatorOption";

export const evaluatorsToArrayString = (arrays: EvaluatorOptionType[]) => {
  if (arrays.length === 0) {
    return [];
  }

  let resultArray: string[] = [];

  resultArray = arrays.map(({ email, inputValue }) => {
    if (!inputValue) {
      return email;
    }

    return inputValue;
  });

  return resultArray;
};
