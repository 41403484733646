// Third Party Type Imports
import { useQuery } from "@tanstack/react-query";
import { AiOutlineCloudDownload } from "react-icons/ai";

// API
import { getFile } from "../../api/file";

// Utils
import {
  formatFileName,
  getExtension,
  getFileSize,
  getUrl,
} from "../../utils/file";

// Types
type Props = {
  id: string;
  name: string;
};

const AttachmentCard = ({ id, name }: Props) => {
  const file = useQuery(["file", id], () => getFile(id));

  return (
    <div className="flex flex-row rounded border border-slate-200 p-2 pr-5 justify-between items-center">
      <div className="flex flex-row justify-start gap-3 mr-6">
        <div className="bg-slate-400 rounded shadow p-3 micro-copy text-black-text max-h-full flex items-center">
          {getExtension(name)}
        </div>
        <div className="flex flex-col">
          <p className="heading-card-title text-black-text">
            {formatFileName(name)}
          </p>
          <span className="micro-copy text-shadow-text">
            {getFileSize(file.data?.size || 0)}
          </span>
        </div>
      </div>
      <a href={getUrl(file.data)} target="_blank" rel="noopener noreferrer">
        <AiOutlineCloudDownload className="text-2xl" />
      </a>
    </div>
  );
};

export default AttachmentCard;
