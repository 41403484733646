// Modules
import { Outlet } from "react-router-dom";

// Components
import { Footer, HomeNavbar } from "../../components";

const HomeLayout = () => {
  return (
    <div className="flex flex-col h-screen">
      <HomeNavbar />
      <div className="flex flex-col p-8 lg:px-48">
        <Outlet />
      </div>
      <div className="mt-auto p-8 lg:px-48">
        <Footer />
      </div>
    </div>
  );
};

export default HomeLayout;
