export interface IndustryOptionType {
  title: string;
}

export const industryOptions: readonly IndustryOptionType[] = [
  { title: "Advanced Manufacturing" },
  { title: "Chemical" },
  { title: "Energy" },
  { title: "Electronics" },
  { title: "Fast Moving Consumer Goods" },
  { title: "General Manufacturing" },
  { title: "Paper Industry" },
  { title: "Pharmaceutical" },
  { title: "Metals & Mining" },
  { title: "Textile & Leathers" },
  { title: "Transportation" },
  { title: "Utilities" },
];
