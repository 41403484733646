import {
  AlertColor,
  Autocomplete,
  Button,
  createFilterOptions,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import parse from "html-react-parser";
import { useEffect, useState } from "react";
import { BiLinkExternal } from "react-icons/bi";
import { Link, useNavigate, useParams } from "react-router-dom";

import { ALERT } from "../../../@consts/alert";
import { defaultSuccess } from "../../../@consts/response";
import { ROLES } from "../../../@consts/roles";
import { challengeStates } from "../../../@consts/state";
import { containedButton } from "../../../@styles/containedButton";
import { outlinedButton } from "../../../@styles/outlinedButton";
import { AttachmentInterface } from "../../../@types/attachment";
import { AuthContextType } from "../../../@types/authContext";
import { EvaluatorOptionType } from "../../../@types/evaluatorOption";
import { ErrorInterface } from "../../../@types/response";
import { SnackbarContextType } from "../../../@types/snackbarContext";
import { SolutionInterface } from "../../../@types/solution";
import {
  approveChallenge,
  assignEvaluators,
  getChallengeDetails,
  getChallengeEvaluators,
} from "../../../api/challenge";
import { getAttachments } from "../../../api/file";
import { getChallengeSolutions } from "../../../api/solution";
import {
  AttachmentCard,
  ChallengeAttributes,
  ChallengeHero,
  ChallengeSolutionRow,
  CommonCard,
  Spinner,
  XSnackbar,
} from "../../../components";
import { useAuthContext } from "../../../contexts/AuthContextProvider";
import { useSnackbarContext } from "../../../contexts/SnackbarContextProvider";
import useLogout from "../../../hooks/useLogout";
import useSnackbar from "../../../hooks/useSnackbar";
import { formatToCurrency } from "../../../utils/currency";
import { evaluatorsToArrayString } from "../../../utils/evaluator";

const filter = createFilterOptions<EvaluatorOptionType>();

const Challenge = () => {
  // Libraries
  const params = useParams();
  const navigate = useNavigate();

  // Constants
  const challengeId = params.challengeId;

  // Contexts
  const { auth } = useAuthContext() as AuthContextType;
  const { openSnackbar, setOpenSnackbar, alertType, alertBody } =
    useSnackbarContext() as SnackbarContextType;

  // Hooks
  const logout = useLogout();
  const snackbar = useSnackbar();

  // Data State
  const [evaluatorEmails, setEvaluatorEmails] = useState<string[]>([]);

  // Components State
  const [openTAC, setOpenTAC] = useState(false);
  const [openDescription, setOpenDescription] = useState(false);
  const [isUserChallenge, setIsUserChallenge] = useState(false);
  const [openAssignForm, setOpenAssignForm] = useState(false);
  const [isAssignedEvaluator, setIsAssignedEvaluator] = useState(false);
  const [openProposedSolution, setOpenProposedSolution] = useState(false);

  // Events
  const handleUserPrevilege = (challengeUserId: string) => {
    if (challengeUserId === auth?.id) {
      setIsUserChallenge(true);
      setOpenDescription(true);
    }
  };

  const handleAdminPrevilege = (authRole: string) => {
    if (authRole === ROLES.ADMIN) {
      setOpenDescription(true);
      setOpenProposedSolution(true);
    }
  };

  const handleAgree = async () => {
    setOpenTAC(!openTAC);

    if (isAssignedEvaluator === false) {
      setOpenDescription(true);
    }
  };

  const handleApproval = (id: string) => {
    approveChallengeMutation.mutate(id);
  };

  const isPublished = () => {
    const result = challenge.data?.data.state === challengeStates.published;

    return result;
  };

  const handleAlert = (alertType: string, alertBody: string) => {
    snackbar(alertType, alertBody);
  };

  const handleEditButton = () => {
    navigate("edit");
  };

  const handleSolverPrevilege = (userSolution: string) => {
    if (userSolution !== null) {
      setOpenDescription(true);
    }
  };

  const validateEmail = (mail: string) => {
    const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (mail.match(mailformat)) {
      return true;
    }
    return false;
  };

  const saveAssignedEvaluator = async () => {
    setOpenAssignForm(!openAssignForm);

    if (evaluatorEmails.length > 0 && challengeId !== undefined) {
      const body = {
        id: challengeId,
        emails: evaluatorEmails,
      };

      assignEvaluatorMutation.mutate(body);
    }
  };

  const handleEvaluatorPrevilege = (isAssignedEvaluator: boolean) => {
    if (!isAssignedEvaluator) return;

    setIsAssignedEvaluator(true);
  };

  // Mutation
  const approveChallengeMutation = useMutation(approveChallenge, {
    onSuccess(data) {
      navigate("/admin/challenges", {
        state: {
          showAlert: true,
          alertType: ALERT.success,
          alertBody: "Challenge successfully approved",
        },
        replace: true,
      });
    },
    onError: (err: ErrorInterface) => {
      if (err.response.status === 401) {
        const unauthorized = err.response.status;

        logout(unauthorized);
      }

      const alertType = ALERT.error;
      const alertBody = err.response.data?.message || err.message;

      handleAlert(alertType, alertBody);
    },
  });

  const assignEvaluatorMutation = useMutation(assignEvaluators, {
    onSuccess(data) {
      challengeEvaluators.refetch();

      const alertType = ALERT.success;
      const alertBody = data.data.message ?? defaultSuccess;

      handleAlert(alertType, alertBody);
    },
    onError: (err: ErrorInterface) => {
      if (err.response.status === 401) {
        const unauthorized = err.response.status;

        logout(unauthorized);
      }

      const alertType = ALERT.error;
      const alertBody = err.response.data?.message || err.message;

      handleAlert(alertType, alertBody);
    },
  });

  // Data Fetching
  const solutions = useQuery(["challenge-solutions", challengeId], async () =>
    getChallengeSolutions(challengeId!)
  );

  const challenge = useQuery(
    ["challenge", challengeId],
    () => getChallengeDetails(challengeId!),
    {
      onSuccess(data) {
        handleUserPrevilege(data.data.userId);

        handleEvaluatorPrevilege(data.data.isAssignedEvaluator);

        handleSolverPrevilege(data.data.userSolutionsForChallenge);
      },
      onError(err: ErrorInterface) {
        if (err.response.status === 401) {
          const unauthorized = err.response.status;

          logout(unauthorized);
        } else if (err.response.status === 404) {
          navigate("/404", {
            replace: true,
          });
        }
      },
    }
  );

  const attachments = useQuery(["challenge-attachments", challengeId], () =>
    getAttachments(challengeId!)
  );

  const challengeEvaluators = useQuery(
    ["challengeEvaluators", challengeId],
    () => getChallengeEvaluators(challengeId!),
    {
      enabled: isPublished(),
    }
  );

  useEffect(() => {
    handleAdminPrevilege(auth?.role!);
  }, []);

  // JSX
  if (challenge.isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <ChallengeHero
        id={challenge.data?.data.id}
        title={challenge.data?.data.title}
        state={challenge.data?.data.state}
      />
      <div className="flex flex-col justify-between gap-5 xl:flex-row ">
        <div className="basis-3/4">
          <div className="flex flex-col">
            <CommonCard title="Challenge Overview">
              {challenge.data?.data.overview}
            </CommonCard>

            {(openDescription || isAssignedEvaluator) && (
              <>
                <CommonCard title="Challenge Details">
                  {parse(challenge.data?.data.details)}
                </CommonCard>

                <CommonCard title="File attachments">
                  {attachments.isLoading ? (
                    <Spinner />
                  ) : (
                    <div className="grid grid-cols-1 gap-5 md:grid-cols-2">
                      {attachments.data?.data.map(
                        (attachment: AttachmentInterface) => (
                          <AttachmentCard
                            key={attachment.id}
                            id={attachment.fileId}
                            name={attachment.fileName}
                          />
                        )
                      )}
                    </div>
                  )}
                </CommonCard>
              </>
            )}

            {(isUserChallenge ||
              isAssignedEvaluator ||
              openProposedSolution) && (
              <CommonCard title="Proposed Solutions">
                <div className="flex flex-row h-fit px-[10px] py-[13px] bg-slate-bg rounded shadow heading-card-title text-shadow-text">
                  <div className="flex-initial basis-7/12">Solution title</div>
                  <div className="flex-initial basis-3/12">Submitted</div>
                  <div className="justify-end flex-initial basis-2/12">
                    Status
                  </div>
                </div>
                {!solutions.data?.data.length ? (
                  <div className="flex flex-col items-center justify-center w-full">
                    <p className="mt-8 text-gray-500 text">
                      No solutions submitted
                    </p>
                  </div>
                ) : (
                  solutions.data?.data!.map((solution: SolutionInterface) => (
                    <ChallengeSolutionRow
                      key={solution.id}
                      id={solution.id}
                      title={solution.title}
                      userId={solution.userId}
                      userName={solution.userName}
                      createdAt={solution.createdAt}
                      state={solution.state}
                    />
                  ))
                )}
              </CommonCard>
            )}
          </div>
        </div>
        <div className="mt-5 basis-1/4">
          <div className="flex flex-col">
            <ChallengeAttributes
              challengeId={challenge.data?.data.id}
              postedId={challenge.data?.data.userId}
              postedBy={challenge.data?.data.userName}
              postedByDisciplies={challenge.data?.data.userCompany}
              award={formatToCurrency(challenge.data?.data.awardAmount)}
              endDate={challenge.data?.data.endDate}
              isUserChallenge={isUserChallenge}
              isPublished={isPublished()}
              challengeEvaluators={challengeEvaluators.data?.data}
            />

            {isUserChallenge ? (
              <>
                <Button
                  disabled={isPublished()}
                  onClick={() => handleEditButton()}
                  className="w-full mt-11 btn btn-primary disabled:cursor-not-allowed"
                >
                  {isPublished()
                    ? "Challange has been published"
                    : "Edit Challenge"}
                </Button>

                {isPublished() && (
                  <Button
                    onClick={() => setOpenAssignForm(!openAssignForm)}
                    className="mt-2 btn btn-primary-outline"
                  >
                    Assign Evaluators
                  </Button>
                )}
              </>
            ) : auth?.role! === ROLES.ADMIN ? (
              <Button
                disabled={isPublished()}
                onClick={() => handleApproval(challenge.data?.data.id)}
                className="w-full mt-11 btn btn-primary disabled:cursor-not-allowed"
              >
                {isPublished() ? "Approved" : "Approve challenge"}
              </Button>
            ) : !openDescription ? (
              <Button
                onClick={() => setOpenTAC(!openTAC)}
                className="w-full mt-11 btn btn-primary"
              >
                {isAssignedEvaluator
                  ? "View challenge terms and conditions"
                  : "View challenge details"}
              </Button>
            ) : (
              <>
                {challenge.data?.data.userSolutionsForChallenge !== null ? (
                  <Link
                    to={`/solutions/${challenge.data?.data.userSolutionsForChallenge}`}
                    className="no-underline"
                  >
                    <Button className="w-full mt-11 btn btn-primary">
                      A solution saved <BiLinkExternal className="ml-1" />
                    </Button>
                  </Link>
                ) : (
                  <Link to="solutions/add" className="no-underline">
                    <Button className="w-full mt-11 btn btn-primary">
                      Propose a solution
                    </Button>
                  </Link>
                )}
              </>
            )}

            <Link
              to="../.."
              className="flex items-center justify-center mt-4 no-underline link"
            >
              Go back
            </Link>
          </div>
        </div>
      </div>

      <Dialog
        open={openTAC}
        onClose={() => setOpenTAC(!openTAC)}
        fullWidth={true}
        maxWidth={"md"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <h2>Terms and Conditions</h2>
        </DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText id="alert-dialog-description">
            {parse(challenge.data?.data.specificTermsAndConditions)}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenTAC(!openTAC)}
            variant="outlined"
            sx={outlinedButton}
          >
            Decline
          </Button>
          <Button
            variant="contained"
            sx={containedButton}
            onClick={handleAgree}
            autoFocus
          >
            Agree
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openAssignForm}
        onClose={() => setOpenAssignForm(!openAssignForm)}
        fullWidth={true}
        maxWidth={"md"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <h2>Assign Evaluators</h2>
        </DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText style={{ marginBottom: "20px" }}>
            To assign evaluators to your challenge, please add email address of
            the evaluators here. We will send notifications to the assigned
            evaluators.
          </DialogContentText>
          <div style={{ marginBottom: "20px" }}>
            <Autocomplete
              id="evaluators"
              multiple
              noOptionsText="Not valid email address"
              options={[] as EvaluatorOptionType[]}
              onChange={(e, arrays) => {
                setEvaluatorEmails(evaluatorsToArrayString(arrays));
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);

                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some(
                  (option) => inputValue === option.email
                );
                if (inputValue !== "" && !isExisting) {
                  const isEmail = validateEmail(inputValue);

                  if (isEmail) {
                    filtered.push({
                      inputValue,
                      email: `Add "${inputValue}"`,
                    });
                  }
                }

                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === "string") {
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue;
                }
                // Regular option
                return option.email;
              }}
              renderOption={(props, option) => (
                <li {...props}>{option.email}</li>
              )}
              renderInput={(params) => (
                <TextField {...params} variant="standard" />
              )}
              style={{ marginTop: "4px" }}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenAssignForm(!openAssignForm)}
            variant="outlined"
            sx={outlinedButton}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={containedButton}
            onClick={() => saveAssignedEvaluator()}
            autoFocus
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <XSnackbar
        open={openSnackbar}
        onClose={() => {
          setOpenSnackbar(false);
        }}
        alertType={alertType as AlertColor}
        alertBody={alertBody}
      />
    </>
  );
};

export default Challenge;
