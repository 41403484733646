import Button from "@mui/material/Button";
import { useQuery } from "@tanstack/react-query";
import { AiOutlineCloudDownload } from "react-icons/ai";
import { CgSortAz } from "react-icons/cg";

import { ErrorInterface } from "../../../@types/response";
import { getGeneratedExcelFileOfUsersList } from "../../../api/excel";
import { getUsersList } from "../../../api/user";
import { ContentHeader, Spinner } from "../../../components";
import useLogout from "../../../hooks/useLogout";
import { getUrl } from "../../../utils/file";

const AdminUsers = () => {
  const logout = useLogout();

  const users = useQuery(["users"], () => getUsersList(), {
    onError(err: ErrorInterface) {
      if (err.response.status === 401) {
        const unauthorized = err.response.status;

        logout(unauthorized);
      }
    },
  });

  const usersListExcel = useQuery(["users-list-excel"], () =>
    getGeneratedExcelFileOfUsersList(),
  );

  return (
    <>
      <div
        id="header"
        className="flex flex-col gap-y-4 md:flex-row md:justify-between"
      >
        <div className="flex flex-col">
          <ContentHeader h1="Users" />
        </div>
        <div className="flex flex-row items-center gap-x-6">
          <a
            href={getUrl(usersListExcel.data)}
            download={"Manuvate Users List.xlsx"}
            rel="noopener noreferrer"
          >
            <Button
              startIcon={<AiOutlineCloudDownload />}
              className="btn btn-primary"
            >
              Download File
            </Button>
          </a>
          <Button
            startIcon={<CgSortAz />}
            className="font-telegraf font-[400px] text-[16px] normal-case leading-[20px] text-black-text hover:bg-transparent hover:shadow-sm"
          >
            Filter by
          </Button>
        </div>
      </div>
      <div id="usersSection" className="mt-5">
        <div className="heading-card-title flex h-fit flex-row rounded bg-white p-[17px] text-shadow-text shadow">
          <div className="flex-initial basis-2/12">Assessor Id</div>
          <div className="flex-initial basis-2/12">Fullname</div>
          <div className="flex-initial basis-2/12">Email</div>
          <div className="flex-initial basis-3/12">Company</div>
          <div className="flex-initial basis-2/12">Country</div>
          <div className="flex-none basis-1/12">Role</div>
        </div>

        {users.isLoading ? (
          <Spinner marginTop />
        ) : users.data != undefined && users.data.data.length < 1 ? (
          <div className="flex w-full flex-col items-center justify-center">
            <p className="text mt-8 text-gray-500">No solutions submitted</p>
          </div>
        ) : (
          users.data?.data.map((user) => (
            <div
              key={user.id}
              className="mt-[15px] flex h-fit flex-row rounded bg-white p-[17px] text-sm text-black-text shadow"
            >
              <div className="flex-initial basis-2/12 text-shadow-text">
                {user.assessorId}
              </div>
              <div className="flex-initial basis-2/12">
                {user.firstName + " " + user.lastName}
              </div>
              <div className="flex-initial basis-2/12">{user.email}</div>
              <div className="flex-initial basis-3/12">{user.companyName}</div>
              <div className="flex-initial basis-2/12">{user.country}</div>
              <div className="flex-none basis-1/12">{user.role}</div>
            </div>
          ))
        )}
      </div>
    </>
  );
};

export default AdminUsers;
