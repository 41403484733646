import "./index.css";

import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material/styles";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import React from "react";
import CacheBuster from "react-cache-buster";
import ReactDOM from "react-dom/client";

import packageInfo from "../package.json";
import App from "./App";
import { AuthProvider } from "./contexts/AuthContextProvider";
import { ContextProvider } from "./contexts/ContextProvider";
import { SnackbarProvider } from "./contexts/SnackbarContextProvider";
import reportWebVitals from "./reportWebVitals";

const queryClient = new QueryClient();

const rootElement = document.getElementById("root");

const theme = createTheme({
  typography: {
    fontFamily: ["Open Sans"].join(","),
  },
  components: {
    MuiDialog: {
      defaultProps: {
        container: rootElement,
      },
    },
  },
});

const isProduction = process.env.REACT_APP_NODE_ENV === "PRODUCTION";

const cacheProps = {
  currentVersion: packageInfo.version,
  isEnabled: isProduction,
  loadingComponent: <h2>Loading...</h2>,
  isVerboseMode: true,
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    <CacheBuster {...cacheProps}>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <ContextProvider>
            <SnackbarProvider>
              <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                  <App />
                </ThemeProvider>
              </StyledEngineProvider>
            </SnackbarProvider>
          </ContextProvider>
        </AuthProvider>

        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </CacheBuster>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
