// Custom Third Party Libraries
import { axiosPrivate } from "./axios";

// Schemas
import { PasswordForm } from "../pages/User/Profiles/consts/password-schemas";
import { ProfileForm } from "../pages/User/Profiles/consts/profile-schemas";
import { AxiosResponse } from "axios";
import { UserInterface } from "../@types/user";

// Types
export type uploadUserPictureType = {
  userId: string;
  body: FormData;
};

// Constants
const baseURL = process.env.REACT_APP_BACKEND_SERVICE_URI;

export const getUserDetails = async (userId: string) => {
  return await axiosPrivate.get(`/users/${userId}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    },
  });
};

export const getUserPicture = async (userId: string) => {
  const accessToken = localStorage.getItem("accessToken") || "null";

  const result = await fetch(`${baseURL}users/${userId}/profile-image`, {
    credentials: "include",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (!result.ok) {
    throw new Error("Network response was not ok");
  }

  const blob = await result.blob();

  const url = URL.createObjectURL(blob);

  return url;
};

export const changePassword = async (values: PasswordForm) => {
  return await axiosPrivate.put(`/users/${values.id}/change-password`, values, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    },
  });
};

export const uploadUserPicture = async (values: uploadUserPictureType) => {
  return await axiosPrivate.post(
    `/users/${values.userId}/image-upload`,
    values.body,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    }
  );
};

export const updateUserProfile = async (values: ProfileForm) => {
  return await axiosPrivate.put(`/users/${values.id}`, values, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    },
  });
};

export const getUserThumbnail = async (userId: string) => {
  const accessToken = localStorage.getItem("accessToken") || "null";

  const result = await fetch(`${baseURL}users/${userId}/sm-profile-image`, {
    credentials: "include",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (!result.ok) {
    throw new Error("Network response was not ok");
  }

  const blob = await result.blob();

  const url = URL.createObjectURL(blob);

  return url;
};

export const getUsersList = async (): Promise<
  AxiosResponse<UserInterface[]>
> => {
  return await axiosPrivate.get(`/users`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    },
  });
};
