// Modules
import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

// API
import { getDashboardDetails } from "../../api/dashboard";
import { getOpenChallenges } from "../../api/challenge";
import { getUserDetails } from "../../api/user";

// Contexts
import { useAuthContext } from "../../contexts/AuthContextProvider";

// Custom Hooks
import useLogout from "../../hooks/useLogout";

// Components
import {
  ChallengeCard,
  ContentHeader,
  Spinner,
  XAvatar,
} from "../../components";

// Assets
import challengesPosted from "./assets/images/challenges-posted.svg";
import solutionsProposed from "./assets/images/solutions-proposed.svg";

// Types
import { AuthContextType } from "../../@types/authContext";
import { ChallengeInterface } from "../../@types/challenge";
import { ErrorInterface } from "../../@types/response";

const Dashboard = () => {
  // Contexts
  const { auth } = useAuthContext() as AuthContextType;

  // Hooks
  const logout = useLogout();

  // Data Fetching
  const user = useQuery(["user", auth?.id], () => getUserDetails(auth?.id!), {
    onError(err: ErrorInterface) {
      if (err.response.status === 401) {
        const unauthorized = err.response.status;

        logout(unauthorized);
      }
    },
  });

  const challenges = useQuery(["dashboard-challenges"], () =>
    getOpenChallenges()
  );

  const dashboard = useQuery(["dashboard", auth?.id], () =>
    getDashboardDetails(auth?.id!)
  );

  // JSX
  return (
    <>
      <ContentHeader
        h1={`Hi ${user.data?.data.firstName} ${user.data?.data.lastName}, welcome back!`}
        p="Welcome to your dashboard."
      />
      <div className="mt-5">
        <div className="grid grid-cols-1 xl:grid-cols-3 gap-5">
          <div className="flex flex-col bg-white rounded shadow w-full h-64 p-6">
            <XAvatar
              id={user.data?.data.id!}
              name={`${user.data?.data.firstName} ${user.data?.data.lastName}`}
              size={96}
              fontSize={38}
            />
            <p className="heading-card-title text-black-text mt-4">
              {`${user.data?.data.firstName} ${user.data?.data.lastName}`}
            </p>
            <p className="heading-card-title text-[14px] text-shadow-text mt-1">
              {user.data?.data.companyName}
            </p>
            <div className="mt-auto">
              <Link to="/user/profiles" className="no-underline">
                <span className="text-blue-mid hover:text-blue-deep text-comment no-underline">
                  Edit profiles
                </span>
              </Link>
            </div>
          </div>
          <div className="flex flex-col bg-white rounded shadow w-full h-64 p-6">
            <p className="text-black-text font-normal text-5xl">
              {dashboard.data?.data.challengeCount}
            </p>
            <p className="mt-1 text text-shadow-text">Challenges posted</p>
            <div className="flex flex-row justify-between h-full">
              <Link to="/user/challenges" className="no-underline mt-4">
                <span className="text-blue-mid hover:text-blue-deep text-comment">
                  View challenges
                </span>
              </Link>
              <img src={challengesPosted} alt="challengesPosted" />
            </div>
          </div>
          <div className="flex flex-col bg-white rounded shadow w-full h-64 p-6">
            <p className="text-black-text font-normal text-5xl">
              {dashboard.data?.data.solutionCount}
            </p>
            <p className="mt-1 text text-shadow-text">Solutions proposed</p>
            <div className="flex flex-row justify-between h-full">
              <Link to="/user/solutions" className="no-underline mt-4">
                <span className="text-blue-mid hover:text-blue-deep text-comment">
                  My solutions
                </span>
              </Link>
              <img src={solutionsProposed} alt="solutionsProposed" />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5">
        <h3>
          Latest challenges |{" "}
          <Link to="/challenges" className="no-underline ">
            <span className="text-blue-mid hover:text-blue-deep">view all</span>
          </Link>
        </h3>
        {challenges.isLoading ? (
          <div className="flex flex-col w-full justify-center items-center">
            <Spinner marginTop />
          </div>
        ) : !challenges.data?.data.length ? (
          <div className="flex flex-col w-full justify-center items-center">
            <p className="text text-gray-500 mt-4">No open challenges posted</p>
            <div className="mt-2">
              <Link to={"/challenges/add"} className="mt-8 no-underline link">
                Create new challenge
              </Link>
            </div>
          </div>
        ) : (
          <div className="mt-5 grid grid-cols-1 sm:grid-cols-2 xl:sm:grid-cols-4 gap-5">
            {challenges.data?.data
              .slice(0, 4)
              .map((challenge: ChallengeInterface) => (
                <ChallengeCard
                  key={challenge.id}
                  id={challenge.id}
                  imageId={challenge.imageId}
                  title={challenge.title}
                  state={challenge.state}
                  awardAmount={challenge.awardAmount}
                  userId={challenge.userId}
                  userName={challenge.userName}
                  endDate={challenge.endDate}
                />
              ))}
          </div>
        )}
      </div>
    </>
  );
};

export default Dashboard;
