// Modules
import { useQuery } from "@tanstack/react-query";

// API
import { getHomepageChallenges } from "../../api/home";

// Components
import { ChallengeCard, ContentHeader, Spinner } from "../../components";

const Home = () => {
  // Data Fetching
  const challenges = useQuery(["challenges"], () => getHomepageChallenges());

  return (
    <>
      <div
        id="header"
        className="flex flex-col gap-y-4 md:flex-row md:justify-between"
      >
        <div className="flex flex-col gap-2">
          <h1>Open Challenges</h1>
          <p className="heading-card-title text-shadow-text">
            ManuVate Innovation Framework in Action.
          </p>
        </div>
      </div>

      {challenges.isLoading ? (
        <Spinner marginTop />
      ) : !challenges.data?.data.length ? (
        <div className="mt-10 flex w-full flex-col items-center justify-center">
          <p className="text mt-4 text-gray-500">No open challenges posted</p>
        </div>
      ) : (
        <>
          <div className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 xl:sm:grid-cols-4">
            {challenges.data?.data.map((challenge) => (
              <ChallengeCard
                key={challenge.id}
                id={challenge.id}
                imageId={challenge.imageId}
                title={challenge.title}
                state={challenge.state}
                userId={challenge.userId}
                endDate={challenge.endDate}
              />
            ))}
          </div>
        </>
      )}
    </>
  );
};

export default Home;
