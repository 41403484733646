export interface DisciplineOptionType {
  inputValue?: string;
  title: string;
}

export const disciplineOptions: DisciplineOptionType[] = [
  { title: "Asset Optimization" },
  { title: "Overall Equipment Efficiency (OEE)" },
  { title: "Connectivity" },
  { title: "Artificial Intelligence" },
  { title: "Blockchain" },
  { title: "Automation" },
  { title: "Data Analytics & Machine Learning" },
  { title: "Digital Assurance" },
  { title: "Quality Management" },
  { title: "Factory Design & Production Layout" },
  { title: "Horizontal Integration" },
  { title: "IOT Enablement" },
  { title: "Edge Computing" },
  { title: "Organization Structure" },
  { title: "Talent Readiness" },
  { title: "Product Lifecycle" },
  { title: "Research & Development" },
  { title: "Start-up Business Model" },
  { title: "Supply Chain/Intra-Logistics" },
  { title: "Sustainability" },
  { title: "Clean-Tech Net Zero" },
  { title: "Vertical Integration" },
];
