// Libraries
import { AxiosResponse } from "axios";

// Custom Third Party Libraries
import { axiosPrivate } from "./axios";

// Types
import { EvaluatorOptionType } from "../@types/evaluatorOption";

export const getEvaluatorOptions = async (): Promise<
  AxiosResponse<EvaluatorOptionType[]>
> => {
  return await axiosPrivate.get(`/evaluators`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    },
  });
};

export const getEvaluatorData = async (solutionId: string) => {
  return await axiosPrivate.get(`/evaluators/solution/${solutionId}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    },
  });
};

export const getEvaluatorScore = async (solutionId: string) => {
  return await axiosPrivate.get(
    `/evaluators/solution/${solutionId}/criteria-scores`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    },
  );
};
