// System
import { Fragment, useState } from "react";

// Modules
import { AiOutlineMenu } from "react-icons/ai";
import { Button } from "@mui/material";
import { HiOutlineLogout } from "react-icons/hi";
import { Link, NavLink } from "react-router-dom";

// Contexts
import { useAuthContext } from "../../contexts/AuthContextProvider";
import { useStateContext } from "../../contexts/ContextProvider";

// Custom Hooks
import useLogout from "../../hooks/useLogout";
import useSidebarLinks from "../../hooks/useSidebarLinks";

// Assets
import manuVateLogoWhite from "./assets/images/manuvate-logo-white.png";
import getSupport from "./assets/images/get-support.svg";

// Types
import { AuthContextType } from "../../@types/authContext";
import { ContextType } from "../../@types/context";

// Constants
import { MdKeyboardArrowDown } from "react-icons/md";

const Sidebar = () => {
  // Constant
  const sidebarLink =
    "flex flex-row gap-5 p-3 pb-1.5 rounded mx-4 my-2 mb-0 no-underline text-white font-bold text-[16px] leading-[26px]";
  const activeLink = sidebarLink + " border border-white shadow-xl";
  const normalLink =
    sidebarLink + " hover:border hover:border-white hover:shadow-xl";

  // Contexts
  const { auth } = useAuthContext() as AuthContextType;
  const { activeMenu, setActiveMenu, screenSize } =
    useStateContext() as ContextType;

  // Hooks
  const logout = useLogout();
  const links = useSidebarLinks();

  // Components state
  const [open, setOpen] = useState(0);
  const [border, setBorder] = useState(0);

  // Events
  const handleCloseSidebar = () => {
    if (screenSize !== undefined) {
      if (activeMenu && screenSize <= 900) {
        setActiveMenu(false);
      }
    }
  };

  const handleOpen = (value: number) => {
    setOpen(open === value ? 0 : value);
  };

  const handleBorder = (value: number) => {
    setBorder(border === value ? 0 : value);
  };

  const signOut = () => {
    logout();
  };

  // JSX
  return (
    <div className="pt-5 pb-8 flex flex-col h-screen md:overflow-hidden overflow-auto md:hover:overflow-auto">
      {activeMenu && (
        <>
          <div className="px-4 flex justify-between h-fit items-center">
            <Link
              to="/dashboard"
              onClick={handleCloseSidebar}
              className="items-left gap-3 flex flex-col text-xl font-extrabold tracking-tight text-white no-underline"
            >
              <img
                src={manuVateLogoWhite}
                alt="ManuVate"
                className="w-52 h-10"
              />
              <div className="pl-2 flex flex-col gap-1">
                <p className="text-hint text-white">
                  Drive Change and Innovate.
                </p>
                <p className="text-hint text-white">Brainstorm Ideas.</p>
                <p className="text-hint text-white">Solve Challenges.</p>
              </div>
            </Link>
            <button
              type="button"
              onClick={() => setActiveMenu(!activeMenu)}
              className="text-xl mb-7 px-1 pt-1 rounded bg-transparent text-white cursor-pointer hover:border hover:border-white hover:shadow-xl block md:hidden"
            >
              <AiOutlineMenu />
            </button>
          </div>
          <div className="mt-14">
            {links.map((item) => (
              <Fragment key={item.title}>
                <p className="mx-7 my-3 uppercase text-white font-normal text-[11px] leading-[15px] tracking-[0.16em]">
                  {item.title}
                </p>
                {item.links.map((link, index) => (
                  <Fragment key={link.name}>
                    {link.role.includes(auth?.role!) && (
                      <>
                        {link.subs ? (
                          <div
                            onClick={() => handleOpen(index)}
                            key={link.name}
                            className={`${normalLink} justify-between items-center cursor-pointer ${
                              border === index && "border border-white"
                            }`}
                          >
                            <div className="flex flex-row gap-5">
                              <span className="text-xl">{link.icon}</span>
                              <span className="capitalize">{link.name}</span>
                            </div>
                            <MdKeyboardArrowDown />
                          </div>
                        ) : (
                          <NavLink
                            key={link.name}
                            to={`/${link.to}`}
                            onClick={() => {
                              handleBorder(index);
                              handleCloseSidebar();
                            }}
                            className={({ isActive }) =>
                              isActive ? activeLink : normalLink
                            }
                          >
                            <span className="text-xl">{link.icon}</span>
                            <span className="capitalize">{link.name}</span>
                          </NavLink>
                        )}
                      </>
                    )}

                    {link.subs && (
                      <div
                        className={`flex flex-col mb-6 pl-[70px] ${
                          open !== index && "hidden"
                        }`}
                      >
                        {link.subs.map((sub) => (
                          <NavLink
                            key={sub.name}
                            to={`/${sub.to}`}
                            onClick={() => {
                              handleCloseSidebar();
                              handleBorder(index);
                            }}
                            className="mt-5 no-underline"
                          >
                            <span className="heading-card-title text-white">
                              {sub.name}
                            </span>
                          </NavLink>
                        ))}
                      </div>
                    )}
                  </Fragment>
                ))}
              </Fragment>
            ))}
            <div
              onClick={signOut}
              className="flex flex-row gap-5 p-3 pb-1.5 rounded mx-4 my-2 mb-0 no-underline text-red-alert font-bold text-[16px] leading-[26px] hover:border hover:border-red-alert hover:shadow-xl cursor-pointer"
            >
              <span className="text-xl">
                <HiOutlineLogout />
              </span>
              <span className="capitalize">Logout</span>
            </div>
          </div>
          <div className="mt-auto">
            <div className="relative mt-28 bg-[#E6F0F1] h-48 rounded mx-6 p-7">
              <div className="absolute -top-20 text-center">
                <img src={getSupport} alt="" className="w-56" />
              </div>
              <div className="flex flex-col h-full text-center pt-9">
                <div className="mt-auto flex flex-col gap-1">
                  <p className="heading-card-title">Getting trouble?</p>
                  <p className="text-hint pb-1">Ask us for help</p>
                  <a
                    href="mailto:PlatformManager@manuvate.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="no-underline"
                  >
                    <Button className="btn btn-primary-outline w-full">
                      Get support
                    </Button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Sidebar;
